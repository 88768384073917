var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-4 elevation-2",staticStyle:{"background-color":"white"}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"10"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pr-6",attrs:{"cols":"2"}},[(_vm.licenceOfficer)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","x-large":""}},'v-btn',attrs,false),on),[_vm._v("Create")])]}}],null,false,726509757),model:{value:(_vm.showCreate),callback:function ($$v) {_vm.showCreate=$$v},expression:"showCreate"}},[_c('licence-class-form',{on:{"create":_vm.createLicenceClass,"close":function($event){_vm.showCreate = false}}})],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.licenceTypeClasses,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.editClass(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteClass(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showUpdateClass),callback:function ($$v) {_vm.showUpdateClass=$$v},expression:"showUpdateClass"}},[_c('licence-class-update-form',{attrs:{"input":_vm.selectedClass},on:{"close":function($event){_vm.showUpdateClass = false},"update":_vm.updateLicenceCLass}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }