



































































































































import {
  ApplicationControllerApi,
  ApplicationGet,
  YesNoComment,
} from "@/api/lms";
import { applicationStore } from "@/store/modules/LicenceApplication";
import { manageLoading } from "@/utils/SnackbarUtil";
import LicenceApplicationIssuer from "@/components/LicenceApplicationIssuer.vue";

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  dialogStore,
  DialogType,
  showLoadingDialog,
} from "@/store/modules/DialogStore";
import { keycloakStore } from "@/store/modules/KeycloakStore";

@Component({
  components: {
    LicenceApplicationIssuer,
  },
})
export default class LicenceApplicationProcessor extends Vue {
  @Prop(Object) readonly input: ApplicationGet | undefined;
  validYesNo: YesNoComment = {};
  valid = "";
  approvalYesNo: YesNoComment = {};
  approve = "";

  app: ApplicationGet | null = null;
  api = new ApplicationControllerApi();

  dialogMessage = "Are you sure you want to complete this action?";

  @Watch("input")
  onAppChanged(newApp: ApplicationGet) {
    this.app = newApp;
    this.validYesNo = {
      comment: this.app.process?.validityComment,
      yesNo: this.app.process?.valid,
    };
    this.valid = this.booleanToString(this.app.process?.valid);
    this.approvalYesNo = {
      comment: this.app.process?.approvalComment,
      yesNo: this.app.process?.approved,
    };
    this.approve = this.booleanToString(this.app.process?.approved);
  }

  get id() {
    return Number(this.$route.params.id);
  }

  get validatedIcon() {
    if (this.app?.process?.valid == true) return "mdi-check-bold";
    else if (this.app?.process?.valid == false) return "mdi-close-thick";
    else return "mdi-minus-thick";
  }

  get validatedColor() {
    if (this.app?.process?.valid == true) return "success";
    else if (this.app?.process?.valid == false) return "error";
    else return "#FFBF00";
  }

  get approvalIcon() {
    if (this.app?.process?.approved == true) return "mdi-check-bold";
    else if (this.app?.process?.approved == false) return "mdi-close-thick";
    else return "mdi-minus-thick";
  }

  get approvalColor() {
    if (this.app?.process?.approved == true) return "success";
    else if (this.app?.process?.approved == false) return "error";
    else return "#FFBF00";
  }

  get approvalEmailIcon() {
    return this.app && this.app.process?.approvalEmailSent
      ? "mdi-check-bold"
      : "mdi-close-thick";
  }

  get approvalEmailColour() {
    return this.app && this.app.process?.approvalEmailSent
      ? "success"
      : "error";
  }

  get validEmailIcon() {
    return this.app && this.app.process?.validEmailSent
      ? "mdi-check-bold"
      : "mdi-close-thick";
  }

  get validEmailColour() {
    return this.app && this.app.process?.validEmailSent ? "success" : "error";
  }

  get deskStaff() {
    return keycloakStore.isDeskStaff;
  }

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  get isPaperSubmission() {
    return this.app && this.app.paperSubmission;
  }

  get isPaid() {
    return this.app && this.app.process?.paid;
  }

  updateValid() {
    showLoadingDialog(
      DialogType.SUCCESS,
      "Updating Validation",
      this.dialogMessage,
      async () => {
        this.validYesNo.yesNo = this.stringToBoolean(this.valid);
        const response = await this.api.validateApplication(
          this.id,
          this.validYesNo
        );
        this.app = response.data;
        applicationStore.updateApplication(response.data);
      }
    );
  }

  updateApproval() {
    showLoadingDialog(
      DialogType.SUCCESS,
      "Updating Approval",
      this.dialogMessage,
      async () => {
        this.approvalYesNo.yesNo = this.stringToBoolean(this.approve);
        const response = await this.api.approveApplication(
          this.id,
          this.approvalYesNo
        );
        this.app = response.data;
        applicationStore.updateApplication(response.data);
      }
    );
  }

  sendValidEmail() {
    showLoadingDialog(
      DialogType.SUCCESS,
      "Sending Validation Email",
      this.dialogMessage,
      async () => {
        const response = await this.api.emailValidation(this.id);
        this.app = response.data;
        applicationStore.updateApplication(response.data);
      }
    );
  }

  sendApprovalEmail() {
    showLoadingDialog(
      DialogType.SUCCESS,
      "Sending Approval Email",
      this.dialogMessage,
      async () => {
        const response = await this.api.emailApproval(this.id);
        this.app = response.data;
        applicationStore.updateApplication(response.data);
      }
    );
  }

  markAsPaid() {
    if (!this.app || !this.app.process) return;
    showLoadingDialog(
      this.app.process.paid ? DialogType.ERROR : DialogType.SUCCESS,
      "Updating Payment Status",
      this.dialogMessage,
      async () => {
        const response = await this.api.markApplicationAsPaid(this.id, {
          yesNo: !this.app?.process?.paid,
        });
        this.app = response.data;
        applicationStore.updateApplication(response.data);
      }
    );
  }

  stringToBoolean(input: string): boolean | undefined {
    if (input == "YES") return true;
    else if (input == "NO") return false;
    return undefined;
  }

  booleanToString(input: boolean | undefined): string {
    if (input == true) return "YES";
    else if (input == false) return "NO";
    return "";
  }
}
