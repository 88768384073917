import {
  Module,
  VuexModule,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

import { manageLoading } from "@/utils/SnackbarUtil";

export enum DialogType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  NOTICE = "NOTICE",
}

interface DialogMessage {
  type: DialogType;
  title: string;
  details: string;
  onSuccess: () => void;
}

@Module({
  name: "dialogStore",
  dynamic: true,
  store,
})
export default class DialogStore extends VuexModule {
  messages: DialogMessage[] = [];

  @Mutation
  dislay(message: DialogMessage) {
    this.messages.push(message);
  }

  @Mutation
  remove() {
    this.messages.pop();
  }

  get nextMessage() {
    return this.messages.length > 0 ? this.messages.pop() : null;
  }
}

export const dialogStore = getModule(DialogStore);

export function showLoadingDialog(
  type: DialogType,
  title: string,
  details: string,
  onSuccess: () => void
) {
  dialogStore.dislay({
    type: type,
    title: title,
    details: details,
    onSuccess: () => {
      manageLoading(title, async () => onSuccess());
    },
  });
}
