

















import { ReportControllerApi } from "@/api/lms";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ReportSelector extends Vue {
  @Prop(String) from: string | undefined;
  @Prop(String) to: string | undefined;
  @Prop(Array) reports: string[] | undefined;

  api = new ReportControllerApi();

  report = "";
  exportLoader = false;

  async createReport() {
    this.exportLoader = true;
    try {
      const response = await this.api.createReport(
        this.from,
        this.to,
        this.report
      );
      if (confirm("Click OK to download report")) open(response.data.link);
    } catch (e) {
      console.log(e);
    } finally {
      this.exportLoader = false;
    }
  }
}
