















































import {
  LicenceClassPost,
  LicenceType,
  LicenceTypeControllerApi,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class LicenceClassForm extends Vue {
  api = new LicenceTypeControllerApi();

  licenceTypes: LicenceType[] = [];

  licenceClass: LicenceClassPost = this.initLicenceClass();

  selectLicenceType = 0;

  mounted() {
    manageLoading("Loading Licence Types", async () => {
      const response = await this.api.getLicenceTypes();
      this.licenceTypes = response.data;
      this.selectLicenceType = this.licenceTypes[0].id;
    });
  }

  initLicenceClass(): LicenceClassPost {
    return {
      name: "",
      description: "",
      recommendedValidDays: 0,
    };
  }

  create() {
    this.$emit("create", {
      licenceTypeId: this.selectLicenceType,
      ...this.licenceClass,
    });
    this.licenceClass = this.initLicenceClass();
  }

  close() {
    this.clear();
    this.$emit("close");
  }

  clear() {
    this.licenceClass = this.initLicenceClass();
  }
}
