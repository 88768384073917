import {
  Module,
  VuexModule,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  name: "keycloakStore",
  dynamic: true,
  store,
})
export default class KeycloakStore extends VuexModule {
  keycloak: any = {};
  roles: string[] = [];

  @Mutation
  setKeycloak(keycloak: any) {
    this.keycloak = keycloak;
  }

  @Mutation
  setRoles(roles: string[]) {
    this.roles = roles;
  }

  get isDeskStaff() {
    return this.roles.includes("desk_staff");
  }

  get isLicenceOfficer() {
    return this.roles.includes("licence_officer");
  }

  get isParrotManager() {
    return this.roles.includes("parrot_manager");
  }
}

export const keycloakStore = getModule(KeycloakStore);
