




























import Vue from "vue";
import Component from "vue-class-component";
import {
  ApplicationControllerApi,
  ApplicationGet,
  LicenceControllerApi,
  LicenceGet,
} from "@/api/lms";
import LicenceManager from "@/components/LicenceManager.vue";
import LicenceForm from "@/components/LicenceForm.vue";
import { manageLoading } from "@/utils/SnackbarUtil";

@Component({
  components: {
    LicenceManager,
    LicenceForm,
  },
})
export default class LicenceManagement extends Vue {
  api = new LicenceControllerApi();
  appApi = new ApplicationControllerApi();

  licence: LicenceGet | null = null;
  app: ApplicationGet | null = null;

  mounted() {
    manageLoading("Loading Licence", async () => {
      const response = await this.api.getLicenceById(
        Number(this.$route.params.id)
      );
      this.licence = response.data;
      const appResonse = await this.appApi.getApplicationById(
        this.licence.application_id
      );
      this.app = appResonse.data;
    });
  }
}
