import {
  Module,
  VuexModule,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface MessageConfigError {
  message: MessageConfig;
  error: string;
}

export interface MessageConfig {
  success: boolean;
  message: string;
  show?: boolean;
  color?: string;
  timeout?: number;
  loading?: boolean;
  id?: number;
}

export const createMessageConfig = (
  messageConfig: MessageConfig
): MessageConfig => ({
  show: true,
  color: "success",
  timeout: 10000,
  loading: false,
  ...messageConfig,
});

export const createErrorMessageConfig = (messageConfig: MessageConfig) =>
  createMessageConfig({
    success: false,
    color: "error",
    message: `${messageConfig.message}`,
    timeout: 20000,
  });

export const createLoadingMessage = (message: string): MessageConfig => ({
  show: true,
  success: true,
  color: "success",
  timeout: 1000 * 60 * 60,
  loading: true,
  message: message,
  id: Math.random(),
});

export const createError = (
  message: MessageConfig,
  error: string
): MessageConfigError => ({
  message: message,
  error: error,
});

@Module({
  name: "snackbars",
  dynamic: true,
  store,
})
export default class Snackbars extends VuexModule {
  messageConfigs: Set<MessageConfig> = new Set();

  @Mutation
  addLoading(messageConfig: MessageConfig) {
    // don't know why this isn't working -> this.addMessage(config)
    this.messageConfigs.add(messageConfig);
    this.messageConfigs = new Set(this.messageConfigs.add(messageConfig));

    if (messageConfig.loading) return;
    setTimeout(() => {
      this.messageConfigs.has(messageConfig) &&
        this.messageConfigs.delete(messageConfig);
      this.messageConfigs = new Set(this.messageConfigs);
    }, messageConfig.timeout);
  }

  @Mutation
  addMessage(messageConfig: MessageConfig) {
    this.messageConfigs.add(messageConfig);
    this.messageConfigs = new Set(this.messageConfigs.add(messageConfig));

    setTimeout(() => {
      this.messageConfigs.has(messageConfig) &&
        this.messageConfigs.delete(messageConfig);
      this.messageConfigs = new Set(this.messageConfigs);
    }, messageConfig.timeout);
  }

  @Mutation
  removeMessage(messageConfig: MessageConfig) {
    this.messageConfigs.has(messageConfig) &&
      this.messageConfigs.delete(messageConfig);
    this.messageConfigs = new Set(this.messageConfigs);
  }

  @Mutation
  replaceWithError(message: MessageConfigError) {
    const messageConfig = message.message;
    const error = message.error;
    this.messageConfigs.has(messageConfig) &&
      this.messageConfigs.delete(messageConfig);
    this.messageConfigs = new Set(this.messageConfigs);
    const errorMessage = createErrorMessageConfig(
      createMessageConfig({ message: error, success: false })
    );
    this.messageConfigs.add(errorMessage);
    this.messageConfigs = new Set(this.messageConfigs.add(errorMessage));

    setTimeout(() => {
      this.messageConfigs.has(errorMessage) &&
        this.messageConfigs.delete(errorMessage);
      this.messageConfigs = new Set(this.messageConfigs);
    }, errorMessage.timeout);
  }
}

export const snackbars = getModule(Snackbars);
