var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.app)?_c('v-card',[_c('v-card-title',[_vm._v(" Issue Licence "),_c('v-spacer'),(!_vm.licenceIssued)?_c('v-btn',{attrs:{"disabled":_vm.app.process.approved != true,"color":"success"},on:{"click":_vm.issueLicence}},[_vm._v("Submit")]):_c('v-btn',{attrs:{"text":"","color":"success","to":'/licence-management/' + _vm.app.licenceId}},[_vm._v(" View Licence "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-check-bold ")])],1)],1),_c('v-divider'),(!_vm.licenceIssued)?_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-select',{attrs:{"items":_vm.licenceTypes,"label":"Licence Type","filled":""},model:{value:(_vm.selectedLicenceType),callback:function ($$v) {_vm.selectedLicenceType=$$v},expression:"selectedLicenceType"}}),_c('v-select',{attrs:{"items":_vm.licenceClasses,"label":"Licence Class","item-text":"name","item-value":"id","filled":""},on:{"change":_vm.setLicenceDates},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Description - "+_vm._s(data.item.description)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-weight-bold"},[_vm._v(" Recommended Valid Days - "+_vm._s(data.item.recommendedValidDays)+" ")])],1)]]}}],null,false,2463566073),model:{value:(_vm.licence.licenceClass),callback:function ($$v) {_vm.$set(_vm.licence, "licenceClass", $$v)},expression:"licence.licenceClass"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.size(_vm.licence.issuedOn, 10)],"label":"Issued On","prepend-icon":"mdi-calendar","readonly":"","filled":""},model:{value:(_vm.licence.issuedOn),callback:function ($$v) {_vm.$set(_vm.licence, "issuedOn", $$v)},expression:"licence.issuedOn"}},'v-text-field',attrs,false),on))]}}],null,false,2712662466),model:{value:(_vm.issuedDateShow),callback:function ($$v) {_vm.issuedDateShow=$$v},expression:"issuedDateShow"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.issuedDateShow = false}},model:{value:(_vm.licence.issuedOn),callback:function ($$v) {_vm.$set(_vm.licence, "issuedOn", $$v)},expression:"licence.issuedOn"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.size(_vm.licence.expiresOn, 10)],"label":"Expires On","prepend-icon":"mdi-calendar","readonly":"","filled":""},model:{value:(_vm.licence.expiresOn),callback:function ($$v) {_vm.$set(_vm.licence, "expiresOn", $$v)},expression:"licence.expiresOn"}},'v-text-field',attrs,false),on))]}}],null,false,3158515711),model:{value:(_vm.expiredDateShow),callback:function ($$v) {_vm.expiredDateShow=$$v},expression:"expiredDateShow"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.expiredDateShow = false}},model:{value:(_vm.licence.expiresOn),callback:function ($$v) {_vm.$set(_vm.licence, "expiresOn", $$v)},expression:"licence.expiresOn"}})],1)],1),_c('v-divider',{staticClass:"mt-5 mb-5"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.categoryHeaders,"items":_vm.licence.categories,"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',{staticClass:"title"},[_vm._v("Categories")]),_c('v-text-field',{attrs:{"label":"Search","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeCategoryItem(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,false,1746722811)})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }