












import { FileControllerApi } from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class FileViewer extends Vue {
  @Prop(String) title: string | undefined;
  @Prop(String) filename: string | undefined;
  @Prop(String) resource: string | undefined;

  api = new FileControllerApi();

  view() {
    manageLoading("Loading file", async () => {
      const response = await this.api.requestFile(
        String(this.filename),
        String(this.resource)
      );
      window.open(response.data.link, "_blank");
    });
  }
}
