var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.category)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.category.category)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteCategory}},[_vm._v(" delete ")])],1)],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.category.species},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-2",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Search","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"success","x-large":""},on:{"click":function($event){return _vm.showManager(true)}}},[_vm._v(" create ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showUpdateManager(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteSpecies(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")])]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [(!item.available)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.showManageDialog),callback:function ($$v) {_vm.showManageDialog=$$v},expression:"showManageDialog"}},[_c('wildlife-species-manager',{attrs:{"input":_vm.selectedSpecies},on:{"create":_vm.createSpecies,"update":_vm.updateSpecies,"close":_vm.hideManager}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }