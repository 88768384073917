import { GroupCount, GroupRegionCount } from "@/api/lms";
import { randomColour, randomColours } from "./ColourUtil";

export function basicChartDataset(source: GroupCount[] | undefined) {
  if (!source) return [];
  return [
    {
      label: "",
      data: source.map((item) => item.count),
      backgroundColor: randomColours(source.length),
      borderWidth: 2,
    },
  ];
}

export function groupRegionToStackBarChart(
  groups: (string | undefined)[],
  data: GroupRegionCount[]
) {
  const colours = groups
    .map((group) => ({ [String(group)]: randomColour() }))
    .reduce((prev, cur) => ({ ...prev, ...cur }), {});

  const dataset = groups.map((group) =>
    stackedChartGroupRegionData(data, String(group), colours[String(group)])
  );

  return {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => `Region ${i}`),
    datasets: dataset,
  };
}

export function stackedChartGroupRegionData(
  source: GroupRegionCount[] | undefined,
  group: string,
  colour: string
) {
  if (!source) return {};
  const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  source
    .filter((item) => item.group == group)
    .forEach((item) => {
      if (item.group === "UNKNOWN")
        data[Number(item.region) - 1] += Number(item.count);
      else data[Number(item.region) - 1] = Number(item.count);
    });
  return {
    label: group,
    data: data,
    backgroundColor: colour,
  };
}
