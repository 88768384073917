





























































































































































































import { ApplicationControllerApi, PartialApplicationGet } from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";
import { applicationStore } from "@/store/modules/LicenceApplication";
import { keycloakStore } from "@/store/modules/KeycloakStore";

interface SubmissionFilter {
  startDate: string;
  startDateShow: boolean;
  endDate: string;
  endDateShow: boolean;
  search: string;
}

@Component
export default class LicenceApplication extends Vue {
  search = "";
  showArchived = false;

  api = new ApplicationControllerApi();

  submissionFilter: SubmissionFilter = this.initSubmissionFilter();

  mounted() {
    if (
      keycloakStore.isParrotManager &&
      (!keycloakStore.isDeskStaff || !keycloakStore.isLicenceOfficer)
    )
      this.$router.push("/parrot-registrations");
    else if (applicationStore.isEmpty) this.refresh();
  }

  get headers() {
    return [
      { text: "Actions", value: "actions", sortable: false },
      { text: "Paper", value: "paperSubmission", sortable: true },
      { text: "App Id", value: "id", sortable: true },
      { text: "First Name", value: "firstName", sortable: true },
      { text: "Last Name", value: "lastName", sortable: true },
      { text: "Email", value: "email", sortable: true },
      { text: "Licence", value: "licenceType", sortable: true },
      { text: "Paid", value: "process.paid", sortable: true },
      { text: "Valid", value: "process.valid", sortable: true },
      { text: "Valid Email", value: "process.validEmailSent", sortable: true },
      { text: "Approved", value: "process.approved", sortable: true },
      {
        text: "Approval Email",
        value: "process.approvalEmailSent",
        sortable: true,
      },
      { text: "Licence Created", value: "licenceId", sortable: true },
      { text: "Created", value: "createdAt", sortable: true },
    ];
  }

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  get applications() {
    return applicationStore.applications.filter(
      (app) => this.showArchived == app.process?.archived
    );
  }

  initSubmissionFilter() {
    const currentDate = new Date();
    currentDate.setDate(1);
    return {
      startDate: currentDate.toISOString().split("T")[0],
      startDateShow: false,
      endDate: "",
      endDateShow: false,
      search: "",
    };
  }

  // refresh() {
  //   manageLoading("Loading Licence Applications", async () => {
  //     const response = await this.api.getApplications();
  //     applicationStore.setApplications(response.data);
  //   });
  // }

  refresh() {
    manageLoading("Loading Licence Applications", async () => {
      if (this.submissionFilter.startDate == null)
        this.submissionFilter.startDate = "";
      if (this.submissionFilter.endDate == null)
        this.submissionFilter.endDate = "";
      if (this.submissionFilter.search == null)
        this.submissionFilter.search = "";
      const response = await this.api.getApplications(
        this.submissionFilter.startDate,
        this.submissionFilter.search,
        this.submissionFilter.endDate
      );
      applicationStore.setApplications(response.data);
    });
  }
}
