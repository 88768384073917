

























































































import WildlifeSpeciesManager from "@/components/WildlifeSpeciesManager.vue";
import {
  WildlifeCategoryControllerApi,
  WildlifeCategoryGet,
  WildlifeSpeciesGet,
  WildlifeSpeciesPost,
} from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    WildlifeSpeciesManager,
  },
})
export default class WildlifeCategoryManager extends Vue {
  api = new WildlifeCategoryControllerApi();

  headers = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Available", value: "available", sortable: true },
    { text: "Scientific Name", value: "scientificName", sortable: true },
    { text: "Common Names", value: "commonNames", sortable: true },
    { text: "Description", value: "description", sortable: true },
  ];

  category: WildlifeCategoryGet = {
    species: [],
    category: "",
  };
  search = "";

  showManageDialog = false;

  selectedSpecies: WildlifeSpeciesGet | null = null;

  mounted() {
    this.refresh();
  }

  get id() {
    return Number(this.$route.params["id"]);
  }

  refresh() {
    manageLoading("Loading Wildlife Species", async () => {
      const response = await this.api.getCategoryById(this.id);
      this.category = response.data;
    });
  }

  deleteCategory() {
    showLoadingDialog(
      DialogType.ERROR,
      "Deleting Wildlife Category",
      "This action cannot be undone. Are you sure you want to delete this Wildlife Category?",
      async () => {
        await this.api.deleteCategory(this.id);
        this.$router.back();
      }
    );
  }

  createSpecies(species: WildlifeSpeciesPost) {
    manageLoading("Creating species", async () => {
      await this.api.addSpecies(this.id, species);
      await this.refresh();
    });
  }

  updateSpecies(species: WildlifeSpeciesPost) {
    if (this.selectedSpecies == null) return;
    manageLoading("Update species", async () => {
      await this.api.updateSpecies(
        this.id,
        Number(this.selectedSpecies?.id),
        species
      );
      await this.refresh();
    });
  }

  deleteSpecies(speciesId: number) {
    showLoadingDialog(
      DialogType.ERROR,
      "Deleting Wildlife Species",
      "This action cannot be undone. Are you sure you want to delete this Wildlife Species?",
      async () => {
        await this.api.deleteSpecies(this.id, speciesId);
        await this.refresh();
      }
    );
  }

  showUpdateManager(item: WildlifeSpeciesGet) {
    this.selectedSpecies = item;
    this.showManager(false);
  }

  showManager(create: boolean) {
    if (create) this.selectedSpecies = null;
    this.showManageDialog = true;
  }

  hideManager() {
    this.showManageDialog = false;
  }
}
