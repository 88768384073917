







































import Vue from "vue";
import Component from "vue-class-component";

import { manageLoading } from "@/utils/SnackbarUtil";

import { AnimalControllerApi, ParrotRegisterGet } from "@/api/lms/api";

@Component
export default class ParrotRegistration extends Vue {
  search = "";
  headers = [
    { text: "Actions", value: "actions" },
    { text: "Owner", value: "fullName" },
    { text: "Contact #", value: "contactNumber" },
    { text: "Email", value: "email" },
    { text: "Scientific Name", value: "parrot" },
    { text: "Unknown Common Name", value: "otherParrot" },
    { text: "Region of Residence", value: "regionOfResidence" },
    { text: "Area of Residence", value: "areaOfResidence" },
  ];
  parrots: ParrotRegisterGet[] = [];
  api = new AnimalControllerApi();

  mounted() {
    manageLoading("Loading Parrot Registrations", async () => {
      const response = await this.api.getRegisteredParrots();
      this.parrots = response.data;
    });
  }
}
