import {
  snackbars,
  createLoadingMessage,
  createError,
  createMessageConfig,
} from "@/store/modules/snackbars";
import { mapActions } from "vuex";

export async function manageLoading(message: string, task: () => any) {
  const info = createLoadingMessage(message);
  snackbars.addLoading(info);
  try {
    await task();
    snackbars.removeMessage(info);
    const completed = createMessageConfig({
      message: `${info.message}`,
      success: true,
      timeout: 3000,
    });
    snackbars.addMessage(completed);
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.data) {
        const error = e.response.data;
        if (error.type == "shallow")
          snackbars.replaceWithError(createError(info, error.error));
        else if (error.type == "deep") {
          const deepError = JSON.parse(error.error);
          const errorValues = deepError
            .flatMap((item: any) => Object.values(item))
            .flatMap((item: any) => Object.values(item))
            .reduce((prev: string, cur: string) =>
              prev.concat(":").concat(cur)
            );
          snackbars.replaceWithError(createError(info, errorValues));
        }
      } else {
        const message =
          e.response.status == 403
            ? "You are not allowed to perform this action."
            : e.response.statusText;
        snackbars.replaceWithError(createError(info, message));
      }
    } else if (e.message) {
      snackbars.replaceWithError(createError(info, e.message));
    } else snackbars.replaceWithError(createError(info, "Unknown error"));
  }
}
