export function min(input: number, min: number): boolean | string {
  return input >= min ? true : `Input must be at least ${min}`;
}

export function minLength(input: string, min: number): boolean | string {
  return input && input.length >= min
    ? true
    : `Input must have at least ${min} character(s)`;
}
export function checkEmail(input: string): boolean | string {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(input) || "Invalid e-mail format.";
}
