

















































































import {
  ApplicationControllerApi,
  ApplicationSummary,
  GroupCount,
  GroupRegionCount,
  WildlifeCategoryControllerApi,
  WildlifeCategoryGet,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

import BarChart from "@/components/charts/BarChart.vue";
import PolarAreaChart from "@/components/charts/PolarAreaChart.vue";
import { randomColour, randomColours } from "@/utils/ColourUtil.ts";
import {
  basicChartDataset,
  groupRegionToStackBarChart,
} from "@/utils/ChartUtil";

import ReportSelector from "@/components/reports/ReportSelector.vue";

@Component({
  components: {
    BarChart,
    PolarAreaChart,
    ReportSelector,
  },
})
export default class ApplicationReport extends Vue {
  api = new ApplicationControllerApi();
  wildlifeApi = new WildlifeCategoryControllerApi();

  summary: ApplicationSummary | null = null;

  categories: string[] = [];

  from = "";
  to = "";

  stackedBarChartOptions = {
    scales: {
      yAxes: [
        {
          stacked: true,
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  reports = [
    "APPLICATIONS",
    "APPLICATION_HARVEST_AREAS",
    "APPLICATION_CATEGORIES",
    "APPLICATION_FIREARMS",
  ];

  async mounted() {
    manageLoading("Loading Wildlife Categories", async () => {
      const response = await this.wildlifeApi.getCategories();
      this.categories = response.data.map((cat) => cat.category);
      await this.refresh();
    });
  }

  refresh() {
    manageLoading("Loading Application Summary", async () => {
      const response = await this.api.getSummary(this.from, this.to);
      this.summary = response.data;
    });
  }

  get regionData() {
    return {
      labels: this.summary?.region.map((item) => `Region ${item.group}`),
      datasets: basicChartDataset(this.summary?.region),
    };
  }

  get licenceTypeData() {
    const total = this.summary?.licenceType
      .map((item) => item.count)
      .reduce((total, value) => Number(total) + Number(value), 0);

    return {
      labels: this.summary?.licenceType.map(
        (item) =>
          `${item.group} (${Math.round(
            (Number(item.count) / Number(total)) * 100
          )}%)`
      ),
      datasets: basicChartDataset(this.summary?.licenceType),
    };
  }

  get categoryData() {
    const categories = this.categoriesWithUnknown();
    const total = categories
      ?.map((item) => item.count)
      .reduce((total, value) => Number(total) + Number(value), 0);
    return {
      labels: categories
        ? categories.map(
            (item) =>
              `${item.group} (${Math.round(
                (Number(item.count) / Number(total)) * 100
              )}%)`
          )
        : [],
      datasets: basicChartDataset(categories),
    };
  }

  get licenceTypeByRegionData() {
    const groups = this.summary?.licenceType.map((item) => item.group);
    if (!groups || !this.summary?.licenceTypeByRegion) return null;
    return groupRegionToStackBarChart(
      groups,
      this.summary?.licenceTypeByRegion
    );
  }

  get categoryTypeByRegionData() {
    const dataset = this.summary?.categoryByRegion;
    const groups = this.categoriesWithUnknown()?.map((cat) => cat.group);
    if (!groups || !dataset) return null;
    const result = groupRegionToStackBarChart(
      groups,
      dataset?.map((cat) => {
        return this.categories.includes(String(cat.group))
          ? cat
          : { ...cat, group: "UNKNOWN" };
      })
    );
    return result;
  }

  categoriesWithUnknown() {
    const knownCategories = this.summary?.category.filter((cat) =>
      this.categories.includes(String(cat.group))
    );
    const unknownCategory = this.summary?.category
      .filter((cat) => !this.categories.includes(String(cat.group)))
      .reduce(
        (prev, curr) => ({
          group: "UNKNOWN",
          count: Number(prev.count) + Number(curr.count),
        }),
        { group: "", count: 0 }
      );
    if (unknownCategory) knownCategories?.push(unknownCategory);
    return knownCategories;
  }
}
