import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Applications from "@/views/Applications.vue";
import ApplicationManager from "@/views/ApplicationManager.vue";
import Licences from "@/views/Licences.vue";
import LicenceManagement from "@/views/LicenceManagement.vue";
import OperationalStrikes from "@/components/OperationalStrikes.vue";

import ParrotRegisters from "@/views/ParrotRegisters.vue";
import Parrots from "@/views/Parrots.vue";
import ParrotManagement from "@/views/ParrotManagement.vue";
import ParrotRegister from "@/components/ParrotRegister.vue";

import LicenceTypes from "@/components/LicenceTypes.vue";
import WildlifeCategories from "@/components/WildlifeCategories.vue";
import WildlifeCategoryManager from "@/components/WildlifeCategoryManager.vue";

import Reports from "@/views/Reports.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Licence Applications",
    component: Applications,
  },
  {
    path: "/application-manager/:id",
    name: "Licence Application Manager",
    component: ApplicationManager,
  },
  {
    path: "/licences",
    name: "Approved Licences",
    component: Licences,
  },
  {
    path: "/licence-types",
    name: "Licence Types",
    component: LicenceTypes,
  },
  {
    path: "/wildlife-categories",
    name: "Wildlife Categories",
    component: WildlifeCategories,
  },
  {
    path: "/wildlife-category-manager/:id",
    name: "Wildlife Category Manager",
    component: WildlifeCategoryManager,
  },
  {
    path: "/strikes",
    name: "Operational Strikes",
    component: OperationalStrikes,
  },
  {
    path: "/licence-management/:id",
    name: "Licence Management",
    component: LicenceManagement,
  },
  {
    path: "/parrots",
    name: "Parrots",
    component: Parrots,
  },
  {
    path: "/parrot-manager",
    name: "Parrot Creator",
    component: ParrotManagement,
  },
  {
    path: "/parrot-manager/:id",
    name: "Parrot Editor",
    component: ParrotManagement,
  },
  {
    path: "/parrot-registrations",
    name: "Parrot Registrations",
    component: ParrotRegisters,
  },
  {
    path: "/parrot-registration/:id",
    name: "Parrot Registration",
    component: ParrotRegister,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
