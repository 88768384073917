















import Vue from "vue";
import Component from "vue-class-component";

import LicenceApplicationProcessor from "@/components/LicenceApplicationProcessor.vue";
import LicenceApplicationForm from "@/components/LicenceApplicationForm.vue";
import { ApplicationControllerApi, ApplicationGet } from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";

@Component({
  components: {
    LicenceApplicationProcessor,
    LicenceApplicationForm,
  },
})
export default class ApplicationManager extends Vue {
  api = new ApplicationControllerApi();
  app: ApplicationGet | null = null;

  mounted() {
    manageLoading("Loading Application", async () => {
      const response = await this.api.getApplicationById(
        Number(this.$route.params.id)
      );
      this.app = response.data;
    });
  }
}
