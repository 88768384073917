




import Vue from "vue";
import Component from "vue-class-component";

import Parrot from "@/components/Parrot.vue";

@Component({
  components: {
    Parrot,
  },
})
export default class Parrots extends Vue {}
