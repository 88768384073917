




import Vue from "vue";
import Component from "vue-class-component";

import LicenceApplication from "@/components/LicenceApplication.vue";

@Component({
  components: {
    LicenceApplication,
  },
})
export default class extends Vue {}
