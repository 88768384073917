var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-4 elevation-2",staticStyle:{"background-color":"white"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.licenceOfficer)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","x-large":""}},'v-btn',attrs,false),on),[_vm._v("Create")])]}}],null,false,726509757),model:{value:(_vm.showCreate),callback:function ($$v) {_vm.showCreate=$$v},expression:"showCreate"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Add Wildlife Category "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showCreate = false}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"label":"Name of Wildlife Category","clearable":"","outlined":""},model:{value:(_vm.categoryPost.category),callback:function ($$v) {_vm.$set(_vm.categoryPost, "category", $$v)},expression:"categoryPost.category"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","block":""},on:{"click":_vm.createCategory}},[_vm._v("Create")])],1)],1)],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.categories,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","to":'/wildlife-category-manager/' + item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Manage")])])]}},{key:"item.species",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.species.length)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }