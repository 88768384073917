













































































































































































































































































































































































































































































































import {
  ApplicationControllerApi,
  ApplicationGet,
  ConvictionPost,
  Gender,
} from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { applicationStore } from "@/store/modules/LicenceApplication";
import { manageLoading } from "@/utils/SnackbarUtil";
import FileViewer from "@/components/FileViewer.vue";

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { keycloakStore } from "@/store/modules/KeycloakStore";
import router from "@/router";

@Component({
  components: {
    FileViewer,
  },
})
export default class LicenceApplicationForm extends Vue {
  @Prop(Object) app: ApplicationGet | undefined;
  api = new ApplicationControllerApi();

  categoryHeaders = [
    { text: "Category", value: "category" },
    { text: "Unknown Category", value: "unknownCategory" },
    { text: "Species", value: "species" },
  ];

  harvestAreaHeaders = [
    { text: "Region", value: "region" },
    { text: "Area/Village", value: "area" },
  ];

  wildlifeInCustodyHeaders = [
    { text: "Species", value: "species" },
    { text: "Dead", value: "dead" },
    { text: "Alive", value: "alive" },
  ];

  firearmHeaders = [
    { text: "Firearm Type", value: "type" },
    { text: "Firearm Number", value: "licenceNumber" },
    { text: "Issued On", value: "issuedOn" },
    { text: "Expires On", value: "expiresOn" },
  ];

  convictionHeaders = [
    { text: "Year", value: "year", sortable: false },
    { text: "Region", value: "region", sortable: false },
    { text: "Area/Village", value: "area", sortable: false },
    { text: "Description", value: "description", sortable: false },
  ];

  clientHeaders = [{ text: "Name", value: "name", sortable: false }];

  showConvictionDescription = false;
  convictionDescription = "";

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  get customers() {
    var items: string[] = [];
    this.app?.businessClients?.forEach((item) => items.push(item));
    this.app?.customerClients?.forEach((item) => items.push(item));
    return items.map((item) => ({ name: item }));
  }

  get suppliers() {
    var items: string[] = [];
    this.app?.businessSuppliers?.forEach((item) => items.push(item));
    this.app?.individualSuppliers?.forEach((item) => items.push(item));
    return items.map((item) => ({ name: item }));
  }

  toggleArchiveApplication() {
    showLoadingDialog(
      DialogType.ERROR,
      "Archiving Application",
      "Are you sure you want to change the archive status of this applicaiton?",
      async () => {
        const response = await this.api.toggleArchiveApplication(
          Number(this.app?.id)
        );
        applicationStore.updateApplication(response.data);
        this.$router.back();
      }
    );
  }

  displayConvictionDescription(input: ConvictionPost) {
    this.convictionDescription = String(input.description);
    this.showConvictionDescription = true;
  }
}
