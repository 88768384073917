/* tslint:disable */
/* eslint-disable */
/**
 * GWCMC Domestic Licence Management System
 * This is a REST API that provides management of Pet Registration and Domestic Wildlife Licences.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: teamgeoideas.@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */
export enum AgeGroup {
    Baby = 'BABY',
    Juvenile = 'JUVENILE',
    Adult = 'ADULT'
}

/**
 * 
 * @export
 * @interface ApplicationGet
 */
export interface ApplicationGet {
    /**
     * 
     * @type {number}
     * @memberof ApplicationGet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationGet
     */
    licenceId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    submissionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    proofOfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    proofOfTIN?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    photo?: string;
    /**
     * 
     * @type {ApplicationProcessGet}
     * @memberof ApplicationGet
     */
    process?: ApplicationProcessGet;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    dob: string;
    /**
     * 
     * @type {Gender}
     * @memberof ApplicationGet
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    nationality: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationGet
     */
    resident?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    countryOfResidence: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof ApplicationGet
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    tin: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    lot: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    community: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationGet
     */
    region?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationGet
     */
    forBusiness?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    proofOfBusinessRegistration?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    proofOfIncorporation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    noticeOfDirectors?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    articlesOfIncorporation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessLot?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessCommunity?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationGet
     */
    businessRegion?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    certificateOfIncorporationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    foodHandlersCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    businessPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    mobilePhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationGet
     */
    preparedByApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    prepareByPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    preparedByIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    licenceType: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationGet
     */
    purpose: string;
    /**
     * 
     * @type {Array<CategoryRequestPost>}
     * @memberof ApplicationGet
     */
    categories: Array<CategoryRequestPost>;
    /**
     * 
     * @type {Array<FirearmGet>}
     * @memberof ApplicationGet
     */
    firearms?: Array<FirearmGet>;
    /**
     * 
     * @type {Array<HarvestAreaPost>}
     * @memberof ApplicationGet
     */
    harvestAreas: Array<HarvestAreaPost>;
    /**
     * 
     * @type {Array<StockPost>}
     * @memberof ApplicationGet
     */
    currentStock?: Array<StockPost>;
    /**
     * 
     * @type {Array<ConvictionPost>}
     * @memberof ApplicationGet
     */
    convictions?: Array<ConvictionPost>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationGet
     */
    customerClients?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationGet
     */
    businessClients?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationGet
     */
    individualSuppliers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationGet
     */
    businessSuppliers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationGet
     */
    paperSubmission?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationPost
 */
export interface ApplicationPost {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    dob: string;
    /**
     * 
     * @type {Gender}
     * @memberof ApplicationPost
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    nationality: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPost
     */
    resident?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    countryOfResidence: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof ApplicationPost
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    tin: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    lot?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    community: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationPost
     */
    region?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPost
     */
    forBusiness?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    proofOfBusinessRegistration?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    proofOfIncorporation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessLot?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessCommunity?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationPost
     */
    businessRegion?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    certificateOfIncorporationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    businessPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    mobilePhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPost
     */
    preparedByApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    prepareByPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    preparedByIdNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationPost
     */
    licenceType?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPost
     */
    purpose: string;
    /**
     * 
     * @type {Array<SpeciesRequestPost>}
     * @memberof ApplicationPost
     */
    species: Array<SpeciesRequestPost>;
    /**
     * 
     * @type {Array<FirearmPost>}
     * @memberof ApplicationPost
     */
    firearms?: Array<FirearmPost>;
    /**
     * 
     * @type {Array<HarvestAreaPost>}
     * @memberof ApplicationPost
     */
    harvestAreas: Array<HarvestAreaPost>;
    /**
     * 
     * @type {Array<StockPost>}
     * @memberof ApplicationPost
     */
    currentStock?: Array<StockPost>;
    /**
     * 
     * @type {Array<ConvictionPost>}
     * @memberof ApplicationPost
     */
    convictions?: Array<ConvictionPost>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPost
     */
    customerClients?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPost
     */
    individualSuppliers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPost
     */
    businessSuppliers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPost
     */
    businessClients?: Array<string>;
}
/**
 * 
 * @export
 * @interface ApplicationProcessGet
 */
export interface ApplicationProcessGet {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProcessGet
     */
    validityComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    validEmailSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProcessGet
     */
    approvalComment?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProcessGet
     */
    approvedRejectedOn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    approvalEmailSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProcessGet
     */
    archived?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationRegistrationRequest
 */
export interface ApplicationRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRegistrationRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ApplicationRenewalRequest
 */
export interface ApplicationRenewalRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRenewalRequest
     */
    email: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof ApplicationRenewalRequest
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRenewalRequest
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRenewalRequest
     */
    oldLicenceNumber: string;
}
/**
 * 
 * @export
 * @interface ApplicationSummary
 */
export interface ApplicationSummary {
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof ApplicationSummary
     */
    region: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof ApplicationSummary
     */
    licenceType: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof ApplicationSummary
     */
    category: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupRegionCount>}
     * @memberof ApplicationSummary
     */
    licenceTypeByRegion: Array<GroupRegionCount>;
    /**
     * 
     * @type {Array<GroupRegionCount>}
     * @memberof ApplicationSummary
     */
    categoryByRegion: Array<GroupRegionCount>;
}
/**
 * This model combines the harvest zone and wildlife category to be harvested in that area.
 * @export
 * @interface CategoryGrantPost
 */
export interface CategoryGrantPost {
    /**
     * Category of wildlife to be harvested.
     * @type {string}
     * @memberof CategoryGrantPost
     */
    category: string;
    /**
     * Species of the wildlife category to be harvested.
     * @type {string}
     * @memberof CategoryGrantPost
     */
    species: string;
    /**
     * Administration Region (Harvest Zone) of Guyana where harvesting will be done.
     * @type {number}
     * @memberof CategoryGrantPost
     */
    region: number;
    /**
     * Area or village in which harvesting will be done.
     * @type {string}
     * @memberof CategoryGrantPost
     */
    area: string;
}
/**
 * 
 * @export
 * @interface CategoryRequestPost
 */
export interface CategoryRequestPost {
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestPost
     */
    category: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryRequestPost
     */
    unknownCategory: boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestPost
     */
    species: string;
}
/**
 * 
 * @export
 * @interface ConvictionPost
 */
export interface ConvictionPost {
    /**
     * 
     * @type {number}
     * @memberof ConvictionPost
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof ConvictionPost
     */
    region: number;
    /**
     * 
     * @type {string}
     * @memberof ConvictionPost
     */
    area: string;
    /**
     * 
     * @type {string}
     * @memberof ConvictionPost
     */
    description: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EscapePrevention {
    ClipWings = 'CLIP_WINGS',
    KeptInCage = 'KEPT_IN_CAGE',
    RestrainedWithRope = 'RESTRAINED_WITH_ROPE',
    NotNecessary = 'NOT_NECESSARY'
}

/**
 * 
 * @export
 * @interface FileLink
 */
export interface FileLink {
    /**
     * 
     * @type {string}
     * @memberof FileLink
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface FirearmGet
 */
export interface FirearmGet {
    /**
     * 
     * @type {string}
     * @memberof FirearmGet
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmGet
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmGet
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmGet
     */
    expiresOn: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmGet
     */
    proof: string;
}
/**
 * 
 * @export
 * @interface FirearmPost
 */
export interface FirearmPost {
    /**
     * 
     * @type {string}
     * @memberof FirearmPost
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmPost
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmPost
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof FirearmPost
     */
    expiresOn: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface GroupCount
 */
export interface GroupCount {
    /**
     * 
     * @type {number}
     * @memberof GroupCount
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupCount
     */
    group?: string;
}
/**
 * 
 * @export
 * @interface GroupRegionCount
 */
export interface GroupRegionCount {
    /**
     * 
     * @type {number}
     * @memberof GroupRegionCount
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupRegionCount
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupRegionCount
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface HarvestAreaPost
 */
export interface HarvestAreaPost {
    /**
     * 
     * @type {number}
     * @memberof HarvestAreaPost
     */
    region?: number;
    /**
     * 
     * @type {string}
     * @memberof HarvestAreaPost
     */
    area: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum HealthCareType {
    SelfCare = 'SELF_CARE',
    VetCare = 'VET_CARE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum IdentificationType {
    IdentificationCard = 'IDENTIFICATION_CARD',
    Passport = 'PASSPORT',
    DriverLicence = 'DRIVER_LICENCE'
}

/**
 * 
 * @export
 * @interface LicenceClassGet
 */
export interface LicenceClassGet {
    /**
     * 
     * @type {string}
     * @memberof LicenceClassGet
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceClassGet
     */
    description: string;
    /**
     * The number of days the licence should be valid. This value is a recommendation and should not be used to determine the validity of a licence.
     * @type {number}
     * @memberof LicenceClassGet
     */
    recommendedValidDays: number;
    /**
     * 
     * @type {number}
     * @memberof LicenceClassGet
     */
    id: number;
}
/**
 * Representation of a Licence Class. Licence Classes are group under Licence Types.
 * @export
 * @interface LicenceClassPost
 */
export interface LicenceClassPost {
    /**
     * 
     * @type {string}
     * @memberof LicenceClassPost
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceClassPost
     */
    description: string;
    /**
     * The number of days the licence should be valid. This value is a recommendation and should not be used to determine the validity of a licence.
     * @type {number}
     * @memberof LicenceClassPost
     */
    recommendedValidDays: number;
}
/**
 * 
 * @export
 * @interface LicenceClassPut
 */
export interface LicenceClassPut {
    /**
     * 
     * @type {string}
     * @memberof LicenceClassPut
     */
    description: string;
}
/**
 * 
 * @export
 * @interface LicenceCollect
 */
export interface LicenceCollect {
    /**
     * 
     * @type {string}
     * @memberof LicenceCollect
     */
    collectedBy: string;
}
/**
 * Representation of issued Licence
 * @export
 * @interface LicenceGet
 */
export interface LicenceGet {
    /**
     * 
     * @type {number}
     * @memberof LicenceGet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    licenceClass: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    licenceType: string;
    /**
     * Application associated with Licence
     * @type {number}
     * @memberof LicenceGet
     */
    application_id: number;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    lastName: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof LicenceGet
     */
    idType: IdentificationType;
    /**
     * Identification number of applicant
     * @type {string}
     * @memberof LicenceGet
     */
    idNumber: string;
    /**
     * Operational Strikes made against Licence by GWCMC
     * @type {Array<OperationalStrikeGet>}
     * @memberof LicenceGet
     */
    strikes?: Array<OperationalStrikeGet>;
    /**
     * Whether the Licence was uplifted
     * @type {boolean}
     * @memberof LicenceGet
     */
    collected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    expiresOn: string;
    /**
     * A list of dictating where each species of wildlife can be harvested from.
     * @type {Array<CategoryGrantPost>}
     * @memberof LicenceGet
     */
    categories: Array<CategoryGrantPost>;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    collectedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    collectedBy: string;
    /**
     * A Licence that was revoked is considered invalid
     * @type {boolean}
     * @memberof LicenceGet
     */
    revoked: boolean;
    /**
     * Determines if the licence fee was paid. If false the licence is considered invalid
     * @type {boolean}
     * @memberof LicenceGet
     */
    paid: boolean;
    /**
     * The scanned licence document
     * @type {string}
     * @memberof LicenceGet
     */
    proofOfLicence: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenceGet
     */
    emailSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceGet
     */
    createdAt: string;
}
/**
 * Representation of a data needed to create a Licence
 * @export
 * @interface LicencePost
 */
export interface LicencePost {
    /**
     * This must be a valid Licence Class. The Licence Class must be within the Licence Type present in the Application
     * @type {number}
     * @memberof LicencePost
     */
    licenceClass: number;
    /**
     * 
     * @type {string}
     * @memberof LicencePost
     */
    issuedOn: string;
    /**
     * 
     * @type {string}
     * @memberof LicencePost
     */
    expiresOn: string;
    /**
     * Harvest Zones granted to this Licence
     * @type {Array<CategoryGrantPost>}
     * @memberof LicencePost
     */
    categories: Array<CategoryGrantPost>;
}
/**
 * 
 * @export
 * @interface LicenceRevocation
 */
export interface LicenceRevocation {
    /**
     * 
     * @type {boolean}
     * @memberof LicenceRevocation
     */
    revoked: boolean;
}
/**
 * 
 * @export
 * @interface LicenceStrike
 */
export interface LicenceStrike {
    /**
     * 
     * @type {number}
     * @memberof LicenceStrike
     */
    licenceId?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    licenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    lastName: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof LicenceStrike
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    tin: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    certificateOfIncorporationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceStrike
     */
    businessRegistrationNumber?: string;
    /**
     * 
     * @type {Array<OperationalStrikeGet>}
     * @memberof LicenceStrike
     */
    strikes?: Array<OperationalStrikeGet>;
}
/**
 * 
 * @export
 * @interface LicenceSummary
 */
export interface LicenceSummary {
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof LicenceSummary
     */
    region: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof LicenceSummary
     */
    licenceType: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof LicenceSummary
     */
    category: Array<GroupCount>;
    /**
     * 
     * @type {Array<GroupRegionCount>}
     * @memberof LicenceSummary
     */
    licenceTypeByRegion: Array<GroupRegionCount>;
    /**
     * 
     * @type {Array<GroupRegionCount>}
     * @memberof LicenceSummary
     */
    categoryByRegion: Array<GroupRegionCount>;
    /**
     * 
     * @type {Array<GroupCount>}
     * @memberof LicenceSummary
     */
    licenceClass: Array<GroupCount>;
}
/**
 * 
 * @export
 * @interface LicenceType
 */
export interface LicenceType {
    /**
     * 
     * @type {number}
     * @memberof LicenceType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LicenceType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceType
     */
    abbreviation: string;
    /**
     * 
     * @type {string}
     * @memberof LicenceType
     */
    description: string;
    /**
     * 
     * @type {Array<LicenceClassGet>}
     * @memberof LicenceType
     */
    classes?: Array<LicenceClassGet>;
}
/**
 * 
 * @export
 * @interface OperationalStrikeGet
 */
export interface OperationalStrikeGet {
    /**
     * 
     * @type {string}
     * @memberof OperationalStrikeGet
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof OperationalStrikeGet
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof OperationalStrikeGet
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface OperationalStrikePost
 */
export interface OperationalStrikePost {
    /**
     * 
     * @type {string}
     * @memberof OperationalStrikePost
     */
    description: string;
}
/**
 * 
 * @export
 * @interface ParrotGet
 */
export interface ParrotGet {
    /**
     * 
     * @type {string}
     * @memberof ParrotGet
     */
    scientificName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParrotGet
     */
    commonNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParrotGet
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotGet
     */
    photo?: string;
    /**
     * 
     * @type {number}
     * @memberof ParrotGet
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface ParrotPost
 */
export interface ParrotPost {
    /**
     * 
     * @type {string}
     * @memberof ParrotPost
     */
    scientificName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParrotPost
     */
    commonNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParrotPost
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotPost
     */
    photo?: string;
}
/**
 * 
 * @export
 * @interface ParrotRegisterGet
 */
export interface ParrotRegisterGet {
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    email?: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof ParrotRegisterGet
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    contactNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    nameOfPet: string;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterGet
     */
    parrotId?: number;
    /**
     * 
     * @type {AgeGroup}
     * @memberof ParrotRegisterGet
     */
    stage: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterGet
     */
    yearAcquired: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    areaOfOrigin?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    areaOfResidence: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    diet?: string;
    /**
     * 
     * @type {HealthCareType}
     * @memberof ParrotRegisterGet
     */
    healthCareType: HealthCareType;
    /**
     * 
     * @type {EscapePrevention}
     * @memberof ParrotRegisterGet
     */
    escapePrevention: EscapePrevention;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterGet
     */
    regionOfOrigin?: number;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterGet
     */
    regionOfResidence: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    photo: string;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterGet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    parrot?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterGet
     */
    otherParrot?: string;
}
/**
 * 
 * @export
 * @interface ParrotRegisterPost
 */
export interface ParrotRegisterPost {
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    email?: string;
    /**
     * 
     * @type {IdentificationType}
     * @memberof ParrotRegisterPost
     */
    idType: IdentificationType;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    contactNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    nameOfPet: string;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterPost
     */
    parrotId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    otherParrot?: string;
    /**
     * 
     * @type {AgeGroup}
     * @memberof ParrotRegisterPost
     */
    stage: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterPost
     */
    yearAcquired: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    areaOfOrigin?: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    areaOfResidence: string;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    diet?: string;
    /**
     * 
     * @type {HealthCareType}
     * @memberof ParrotRegisterPost
     */
    healthCareType: HealthCareType;
    /**
     * 
     * @type {EscapePrevention}
     * @memberof ParrotRegisterPost
     */
    escapePrevention: EscapePrevention;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterPost
     */
    regionOfOrigin?: number;
    /**
     * 
     * @type {number}
     * @memberof ParrotRegisterPost
     */
    regionOfResidence: number;
    /**
     * 
     * @type {string}
     * @memberof ParrotRegisterPost
     */
    photo: string;
}
/**
 * 
 * @export
 * @interface ParrotRegistrationSummary
 */
export interface ParrotRegistrationSummary {
    /**
     * 
     * @type {number}
     * @memberof ParrotRegistrationSummary
     */
    region?: number;
    /**
     * 
     * @type {Array<ParrotType>}
     * @memberof ParrotRegistrationSummary
     */
    types?: Array<ParrotType>;
}
/**
 * 
 * @export
 * @interface ParrotType
 */
export interface ParrotType {
    /**
     * 
     * @type {string}
     * @memberof ParrotType
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ParrotType
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface PartialApplicationGet
 */
export interface PartialApplicationGet {
    /**
     * 
     * @type {number}
     * @memberof PartialApplicationGet
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PartialApplicationGet
     */
    licenceId?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialApplicationGet
     */
    paperSubmission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialApplicationGet
     */
    licenceType?: string;
    /**
     * 
     * @type {ApplicationProcessGet}
     * @memberof PartialApplicationGet
     */
    process?: ApplicationProcessGet;
}
/**
 * 
 * @export
 * @interface SpeciesRequestPost
 */
export interface SpeciesRequestPost {
    /**
     * 
     * @type {number}
     * @memberof SpeciesRequestPost
     */
    speciesId?: number;
    /**
     * 
     * @type {string}
     * @memberof SpeciesRequestPost
     */
    other?: string;
}
/**
 * 
 * @export
 * @interface StockPost
 */
export interface StockPost {
    /**
     * 
     * @type {string}
     * @memberof StockPost
     */
    species: string;
    /**
     * 
     * @type {number}
     * @memberof StockPost
     */
    alive?: number;
    /**
     * 
     * @type {number}
     * @memberof StockPost
     */
    dead?: number;
}
/**
 * Representation of the category of wildlife for available for harvesting
 * @export
 * @interface WildlifeCategoryGet
 */
export interface WildlifeCategoryGet {
    /**
     * The name of the wildlife category, example TROPICAL_FISH.
     * @type {string}
     * @memberof WildlifeCategoryGet
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof WildlifeCategoryGet
     */
    id?: number;
    /**
     * 
     * @type {Array<WildlifeSpeciesGet>}
     * @memberof WildlifeCategoryGet
     */
    species: Array<WildlifeSpeciesGet>;
}
/**
 * Representation of the category of wildlife to be made available for harvesting.
 * @export
 * @interface WildlifeCategoryPost
 */
export interface WildlifeCategoryPost {
    /**
     * The name of the wildlife category, example TROPICAL_FISH.
     * @type {string}
     * @memberof WildlifeCategoryPost
     */
    category: string;
}
/**
 * 
 * @export
 * @interface WildlifeSpeciesGet
 */
export interface WildlifeSpeciesGet {
    /**
     * 
     * @type {Array<string>}
     * @memberof WildlifeSpeciesGet
     */
    commonNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WildlifeSpeciesGet
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof WildlifeSpeciesGet
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof WildlifeSpeciesGet
     */
    scientificName: string;
    /**
     * 
     * @type {number}
     * @memberof WildlifeSpeciesGet
     */
    id: number;
}
/**
 * 
 * @export
 * @interface WildlifeSpeciesPost
 */
export interface WildlifeSpeciesPost {
    /**
     * 
     * @type {Array<string>}
     * @memberof WildlifeSpeciesPost
     */
    commonNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WildlifeSpeciesPost
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof WildlifeSpeciesPost
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof WildlifeSpeciesPost
     */
    scientificName: string;
}
/**
 * 
 * @export
 * @interface WildlifeSpeciesPut
 */
export interface WildlifeSpeciesPut {
    /**
     * 
     * @type {Array<string>}
     * @memberof WildlifeSpeciesPut
     */
    commonNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WildlifeSpeciesPut
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof WildlifeSpeciesPut
     */
    available: boolean;
}
/**
 * 
 * @export
 * @interface YesNoComment
 */
export interface YesNoComment {
    /**
     * 
     * @type {boolean}
     * @memberof YesNoComment
     */
    yesNo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof YesNoComment
     */
    comment?: string;
}

/**
 * AnimalControllerApi - axios parameter creator
 * @export
 */
export const AnimalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParrot: async (parrotPost?: ParrotPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/animals/parrots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parrotPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParrotById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteParrotById', 'id', id)
            const localVarPath = `/api/v1/animals/parrots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParrotById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParrotById', 'id', id)
            const localVarPath = `/api/v1/animals/parrots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParrots: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/animals/parrots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrotById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegisteredParrotById', 'id', id)
            const localVarPath = `/api/v1/animals/register/parrots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrotSummary: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/animals/register/parrots/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrots: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/animals/register/parrots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParrotRegisterPost} [parrotRegisterPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerParrot: async (parrotRegisterPost?: ParrotRegisterPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/animals/register/parrots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parrotRegisterPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParrotById: async (id: number, parrotPost?: ParrotPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateParrotById', 'id', id)
            const localVarPath = `/api/v1/animals/parrots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parrotPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnimalControllerApi - functional programming interface
 * @export
 */
export const AnimalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnimalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addParrot(parrotPost?: ParrotPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParrotGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addParrot(parrotPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParrotById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParrotById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParrotById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParrotGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParrotById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParrots(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParrotGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParrots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredParrotById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParrotRegisterGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredParrotById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredParrotSummary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParrotRegistrationSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredParrotSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredParrots(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParrotRegisterGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredParrots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParrotRegisterPost} [parrotRegisterPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerParrot(parrotRegisterPost?: ParrotRegisterPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParrotRegisterGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerParrot(parrotRegisterPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParrotById(id: number, parrotPost?: ParrotPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParrotGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParrotById(id, parrotPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnimalControllerApi - factory interface
 * @export
 */
export const AnimalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnimalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParrot(parrotPost?: ParrotPost, options?: any): AxiosPromise<ParrotGet> {
            return localVarFp.addParrot(parrotPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParrotById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteParrotById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParrotById(id: number, options?: any): AxiosPromise<ParrotGet> {
            return localVarFp.getParrotById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParrots(options?: any): AxiosPromise<Array<ParrotGet>> {
            return localVarFp.getParrots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrotById(id: number, options?: any): AxiosPromise<ParrotRegisterGet> {
            return localVarFp.getRegisteredParrotById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrotSummary(options?: any): AxiosPromise<Array<ParrotRegistrationSummary>> {
            return localVarFp.getRegisteredParrotSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredParrots(options?: any): AxiosPromise<Array<ParrotRegisterGet>> {
            return localVarFp.getRegisteredParrots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParrotRegisterPost} [parrotRegisterPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerParrot(parrotRegisterPost?: ParrotRegisterPost, options?: any): AxiosPromise<ParrotRegisterGet> {
            return localVarFp.registerParrot(parrotRegisterPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParrotPost} [parrotPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParrotById(id: number, parrotPost?: ParrotPost, options?: any): AxiosPromise<ParrotGet> {
            return localVarFp.updateParrotById(id, parrotPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnimalControllerApi - object-oriented interface
 * @export
 * @class AnimalControllerApi
 * @extends {BaseAPI}
 */
export class AnimalControllerApi extends BaseAPI {
    /**
     * 
     * @param {ParrotPost} [parrotPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public addParrot(parrotPost?: ParrotPost, options?: any) {
        return AnimalControllerApiFp(this.configuration).addParrot(parrotPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public deleteParrotById(id: number, options?: any) {
        return AnimalControllerApiFp(this.configuration).deleteParrotById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public getParrotById(id: number, options?: any) {
        return AnimalControllerApiFp(this.configuration).getParrotById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public getParrots(options?: any) {
        return AnimalControllerApiFp(this.configuration).getParrots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public getRegisteredParrotById(id: number, options?: any) {
        return AnimalControllerApiFp(this.configuration).getRegisteredParrotById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public getRegisteredParrotSummary(options?: any) {
        return AnimalControllerApiFp(this.configuration).getRegisteredParrotSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public getRegisteredParrots(options?: any) {
        return AnimalControllerApiFp(this.configuration).getRegisteredParrots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParrotRegisterPost} [parrotRegisterPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public registerParrot(parrotRegisterPost?: ParrotRegisterPost, options?: any) {
        return AnimalControllerApiFp(this.configuration).registerParrot(parrotRegisterPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParrotPost} [parrotPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalControllerApi
     */
    public updateParrotById(id: number, parrotPost?: ParrotPost, options?: any) {
        return AnimalControllerApiFp(this.configuration).updateParrotById(id, parrotPost, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationControllerApi - axios parameter creator
 * @export
 */
export const ApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicencePost} [licencePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLicence: async (id: number, licencePost?: LicencePost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addLicence', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/licence`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licencePost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplication: async (id: number, yesNoComment?: YesNoComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveApplication', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yesNoComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {any} identification 
         * @param {any} photo 
         * @param {any} tin 
         * @param {string} details 
         * @param {any} [firearmOne] 
         * @param {any} [firearmTwo] 
         * @param {any} [businessRegistration] 
         * @param {any} [businessIncorporation] 
         * @param {any} [noticeOfDirectors] 
         * @param {any} [articlesOfIncorporation] 
         * @param {any} [foodHandlersCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (token: string, identification: any, photo: any, tin: any, details: string, firearmOne?: any, firearmTwo?: any, businessRegistration?: any, businessIncorporation?: any, noticeOfDirectors?: any, articlesOfIncorporation?: any, foodHandlersCertificate?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('createApplication', 'token', token)
            // verify required parameter 'identification' is not null or undefined
            assertParamExists('createApplication', 'identification', identification)
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('createApplication', 'photo', photo)
            // verify required parameter 'tin' is not null or undefined
            assertParamExists('createApplication', 'tin', tin)
            // verify required parameter 'details' is not null or undefined
            assertParamExists('createApplication', 'details', details)
            const localVarPath = `/api/v1/applications/registration/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (identification !== undefined) { 
                localVarFormParams.append('identification', new Blob([JSON.stringify(identification)], { type: "application/json", }));
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('photo', new Blob([JSON.stringify(photo)], { type: "application/json", }));
            }
    
            if (tin !== undefined) { 
                localVarFormParams.append('tin', new Blob([JSON.stringify(tin)], { type: "application/json", }));
            }
    
            if (firearmOne !== undefined) { 
                localVarFormParams.append('firearmOne', new Blob([JSON.stringify(firearmOne)], { type: "application/json", }));
            }
    
            if (firearmTwo !== undefined) { 
                localVarFormParams.append('firearmTwo', new Blob([JSON.stringify(firearmTwo)], { type: "application/json", }));
            }
    
            if (businessRegistration !== undefined) { 
                localVarFormParams.append('businessRegistration', new Blob([JSON.stringify(businessRegistration)], { type: "application/json", }));
            }
    
            if (businessIncorporation !== undefined) { 
                localVarFormParams.append('businessIncorporation', new Blob([JSON.stringify(businessIncorporation)], { type: "application/json", }));
            }
    
            if (noticeOfDirectors !== undefined) { 
                localVarFormParams.append('noticeOfDirectors', new Blob([JSON.stringify(noticeOfDirectors)], { type: "application/json", }));
            }
    
            if (articlesOfIncorporation !== undefined) { 
                localVarFormParams.append('articlesOfIncorporation', new Blob([JSON.stringify(articlesOfIncorporation)], { type: "application/json", }));
            }
    
            if (foodHandlersCertificate !== undefined) { 
                localVarFormParams.append('foodHandlersCertificate', new Blob([JSON.stringify(foodHandlersCertificate)], { type: "application/json", }));
            }
    
            if (details !== undefined) { 
                localVarFormParams.append('details', details as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailApproval: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailApproval', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/approve/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailValidation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailValidation', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/validate/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationById', 'id', id)
            const localVarPath = `/api/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFromToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getApplicationFromToken', 'token', token)
            const localVarPath = `/api/v1/applications/renewal/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [search] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (from?: string, search?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationRegistrationRequest} [applicationRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationToken: async (applicationRegistrationRequest?: ApplicationRegistrationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications/registration-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationRenewalRequest} [applicationRenewalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRenewalApplicationToken: async (applicationRenewalRequest?: ApplicationRenewalRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications/renewal-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationRenewalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markApplicationAsPaid: async (id: number, yesNoComment?: YesNoComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markApplicationAsPaid', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yesNoComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleArchiveApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toggleArchiveApplication', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateApplication: async (id: number, yesNoComment?: YesNoComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateApplication', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yesNoComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationControllerApi - functional programming interface
 * @export
 */
export const ApplicationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicencePost} [licencePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLicence(id: number, licencePost?: LicencePost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLicence(id, licencePost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveApplication(id: number, yesNoComment?: YesNoComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveApplication(id, yesNoComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {any} identification 
         * @param {any} photo 
         * @param {any} tin 
         * @param {string} details 
         * @param {any} [firearmOne] 
         * @param {any} [firearmTwo] 
         * @param {any} [businessRegistration] 
         * @param {any} [businessIncorporation] 
         * @param {any} [noticeOfDirectors] 
         * @param {any} [articlesOfIncorporation] 
         * @param {any} [foodHandlersCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(token: string, identification: any, photo: any, tin: any, details: string, firearmOne?: any, firearmTwo?: any, businessRegistration?: any, businessIncorporation?: any, noticeOfDirectors?: any, articlesOfIncorporation?: any, foodHandlersCertificate?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(token, identification, photo, tin, details, firearmOne, firearmTwo, businessRegistration, businessIncorporation, noticeOfDirectors, articlesOfIncorporation, foodHandlersCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailApproval(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailApproval(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailValidation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailValidation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationFromToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationFromToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [search] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(from?: string, search?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialApplicationGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(from, search, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationRegistrationRequest} [applicationRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationToken(applicationRegistrationRequest?: ApplicationRegistrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationToken(applicationRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationRenewalRequest} [applicationRenewalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRenewalApplicationToken(applicationRenewalRequest?: ApplicationRenewalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRenewalApplicationToken(applicationRenewalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markApplicationAsPaid(id: number, yesNoComment?: YesNoComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markApplicationAsPaid(id, yesNoComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleArchiveApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleArchiveApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateApplication(id: number, yesNoComment?: YesNoComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateApplication(id, yesNoComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationControllerApi - factory interface
 * @export
 */
export const ApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicencePost} [licencePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLicence(id: number, licencePost?: LicencePost, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.addLicence(id, licencePost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplication(id: number, yesNoComment?: YesNoComment, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.approveApplication(id, yesNoComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {any} identification 
         * @param {any} photo 
         * @param {any} tin 
         * @param {string} details 
         * @param {any} [firearmOne] 
         * @param {any} [firearmTwo] 
         * @param {any} [businessRegistration] 
         * @param {any} [businessIncorporation] 
         * @param {any} [noticeOfDirectors] 
         * @param {any} [articlesOfIncorporation] 
         * @param {any} [foodHandlersCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(token: string, identification: any, photo: any, tin: any, details: string, firearmOne?: any, firearmTwo?: any, businessRegistration?: any, businessIncorporation?: any, noticeOfDirectors?: any, articlesOfIncorporation?: any, foodHandlersCertificate?: any, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.createApplication(token, identification, photo, tin, details, firearmOne, firearmTwo, businessRegistration, businessIncorporation, noticeOfDirectors, articlesOfIncorporation, foodHandlersCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailApproval(id: number, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.emailApproval(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailValidation(id: number, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.emailValidation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById(id: number, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.getApplicationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFromToken(token: string, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.getApplicationFromToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [search] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(from?: string, search?: string, to?: string, options?: any): AxiosPromise<Array<PartialApplicationGet>> {
            return localVarFp.getApplications(from, search, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationRegistrationRequest} [applicationRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationToken(applicationRegistrationRequest?: ApplicationRegistrationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.getRegistrationToken(applicationRegistrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationRenewalRequest} [applicationRenewalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRenewalApplicationToken(applicationRenewalRequest?: ApplicationRenewalRequest, options?: any): AxiosPromise<void> {
            return localVarFp.getRenewalApplicationToken(applicationRenewalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(from?: string, to?: string, options?: any): AxiosPromise<ApplicationSummary> {
            return localVarFp.getSummary(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markApplicationAsPaid(id: number, yesNoComment?: YesNoComment, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.markApplicationAsPaid(id, yesNoComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleArchiveApplication(id: number, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.toggleArchiveApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateApplication(id: number, yesNoComment?: YesNoComment, options?: any): AxiosPromise<ApplicationGet> {
            return localVarFp.validateApplication(id, yesNoComment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationControllerApi - object-oriented interface
 * @export
 * @class ApplicationControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {LicencePost} [licencePost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public addLicence(id: number, licencePost?: LicencePost, options?: any) {
        return ApplicationControllerApiFp(this.configuration).addLicence(id, licencePost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {YesNoComment} [yesNoComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public approveApplication(id: number, yesNoComment?: YesNoComment, options?: any) {
        return ApplicationControllerApiFp(this.configuration).approveApplication(id, yesNoComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {any} identification 
     * @param {any} photo 
     * @param {any} tin 
     * @param {string} details 
     * @param {any} [firearmOne] 
     * @param {any} [firearmTwo] 
     * @param {any} [businessRegistration] 
     * @param {any} [businessIncorporation] 
     * @param {any} [noticeOfDirectors] 
     * @param {any} [articlesOfIncorporation] 
     * @param {any} [foodHandlersCertificate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public createApplication(token: string, identification: any, photo: any, tin: any, details: string, firearmOne?: any, firearmTwo?: any, businessRegistration?: any, businessIncorporation?: any, noticeOfDirectors?: any, articlesOfIncorporation?: any, foodHandlersCertificate?: any, options?: any) {
        return ApplicationControllerApiFp(this.configuration).createApplication(token, identification, photo, tin, details, firearmOne, firearmTwo, businessRegistration, businessIncorporation, noticeOfDirectors, articlesOfIncorporation, foodHandlersCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public emailApproval(id: number, options?: any) {
        return ApplicationControllerApiFp(this.configuration).emailApproval(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public emailValidation(id: number, options?: any) {
        return ApplicationControllerApiFp(this.configuration).emailValidation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplicationById(id: number, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getApplicationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplicationFromToken(token: string, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getApplicationFromToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [search] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplications(from?: string, search?: string, to?: string, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getApplications(from, search, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationRegistrationRequest} [applicationRegistrationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getRegistrationToken(applicationRegistrationRequest?: ApplicationRegistrationRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getRegistrationToken(applicationRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationRenewalRequest} [applicationRenewalRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getRenewalApplicationToken(applicationRenewalRequest?: ApplicationRenewalRequest, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getRenewalApplicationToken(applicationRenewalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getSummary(from?: string, to?: string, options?: any) {
        return ApplicationControllerApiFp(this.configuration).getSummary(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {YesNoComment} [yesNoComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public markApplicationAsPaid(id: number, yesNoComment?: YesNoComment, options?: any) {
        return ApplicationControllerApiFp(this.configuration).markApplicationAsPaid(id, yesNoComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public toggleArchiveApplication(id: number, options?: any) {
        return ApplicationControllerApiFp(this.configuration).toggleArchiveApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {YesNoComment} [yesNoComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public validateApplication(id: number, yesNoComment?: YesNoComment, options?: any) {
        return ApplicationControllerApiFp(this.configuration).validateApplication(id, yesNoComment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFile: async (filename: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getApplicationFile', 'filename', filename)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getApplicationFile', 'token', token)
            const localVarPath = `/api/v1/files/applications/renewal/{filename}/{token}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (filename: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getFile', 'filename', filename)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getFile', 'token', token)
            const localVarPath = `/api/v1/files/{token}/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile: async (filename: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getReportFile', 'filename', filename)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getReportFile', 'token', token)
            const localVarPath = `/api/v1/files/reports/{token}/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFile: async (filename: string, resource?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('requestFile', 'filename', filename)
            const localVarPath = `/api/v1/files/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (resource !== undefined) {
                localVarQueryParameter['resource'] = resource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationFile(filename: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationFile(filename, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(filename: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(filename, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportFile(filename: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFile(filename, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestFile(filename: string, resource?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestFile(filename, resource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFile(filename: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.getApplicationFile(filename, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(filename: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.getFile(filename, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile(filename: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.getReportFile(filename, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} [resource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFile(filename: string, resource?: string, options?: any): AxiosPromise<FileLink> {
            return localVarFp.requestFile(filename, resource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getApplicationFile(filename: string, token: string, options?: any) {
        return FileControllerApiFp(this.configuration).getApplicationFile(filename, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getFile(filename: string, token: string, options?: any) {
        return FileControllerApiFp(this.configuration).getFile(filename, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getReportFile(filename: string, token: string, options?: any) {
        return FileControllerApiFp(this.configuration).getReportFile(filename, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filename 
     * @param {string} [resource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public requestFile(filename: string, resource?: string, options?: any) {
        return FileControllerApiFp(this.configuration).requestFile(filename, resource, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenceControllerApi - axios parameter creator
 * @export
 */
export const LicenceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add Operational Strike to Licence
         * @param {number} id 
         * @param {OperationalStrikePost} [operationalStrikePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperationalStrike: async (id: number, operationalStrikePost?: OperationalStrikePost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addOperationalStrike', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/strikes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operationalStrikePost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark licence as uplifted
         * @param {number} id 
         * @param {LicenceCollect} [licenceCollect] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectLicence: async (id: number, licenceCollect?: LicenceCollect, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectLicence', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/collect`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenceCollect, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Licence e-mail notice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLicenceReady: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailLicenceReady', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/collect/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate licence for printing
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLicence: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateLicence', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/print`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get one licence
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenceById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenceById', 'id', id)
            const localVarPath = `/api/v1/licences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Licences
         * @param {string} [from] Lower date value for Licence issuedOn property
         * @param {string} [search] Search by licenceNumber and collectedBy
         * @param {string} [to] Upper date value for Licence issuedOn property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicences: async (from?: string, search?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Operational Strikes made against a Licence
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStrikes: async (search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licences/strikes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Licence summary data
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (from?: string, to?: string, typeId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licences/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update licence payment
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markLicenceAsPaid: async (id: number, yesNoComment?: YesNoComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markLicenceAsPaid', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yesNoComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update revoked status of Licence
         * @param {number} id 
         * @param {LicenceRevocation} [licenceRevocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicence: async (id: number, licenceRevocation?: LicenceRevocation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revokeLicence', 'id', id)
            const localVarPath = `/api/v1/licences/{id}/revocations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenceRevocation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenceControllerApi - functional programming interface
 * @export
 */
export const LicenceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Add Operational Strike to Licence
         * @param {number} id 
         * @param {OperationalStrikePost} [operationalStrikePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOperationalStrike(id: number, operationalStrikePost?: OperationalStrikePost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOperationalStrike(id, operationalStrikePost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark licence as uplifted
         * @param {number} id 
         * @param {LicenceCollect} [licenceCollect] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectLicence(id: number, licenceCollect?: LicenceCollect, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectLicence(id, licenceCollect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send Licence e-mail notice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailLicenceReady(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailLicenceReady(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate licence for printing
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateLicence(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateLicence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get one licence
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenceById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Licences
         * @param {string} [from] Lower date value for Licence issuedOn property
         * @param {string} [search] Search by licenceNumber and collectedBy
         * @param {string} [to] Upper date value for Licence issuedOn property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicences(from?: string, search?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicences(from, search, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Operational Strikes made against a Licence
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStrikes(search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceStrike>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStrikes(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Licence summary data
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(from?: string, to?: string, typeId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(from, to, typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update licence payment
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markLicenceAsPaid(id: number, yesNoComment?: YesNoComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markLicenceAsPaid(id, yesNoComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update revoked status of Licence
         * @param {number} id 
         * @param {LicenceRevocation} [licenceRevocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeLicence(id: number, licenceRevocation?: LicenceRevocation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeLicence(id, licenceRevocation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenceControllerApi - factory interface
 * @export
 */
export const LicenceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenceControllerApiFp(configuration)
    return {
        /**
         * Add Operational Strike to Licence
         * @param {number} id 
         * @param {OperationalStrikePost} [operationalStrikePost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperationalStrike(id: number, operationalStrikePost?: OperationalStrikePost, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.addOperationalStrike(id, operationalStrikePost, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark licence as uplifted
         * @param {number} id 
         * @param {LicenceCollect} [licenceCollect] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectLicence(id: number, licenceCollect?: LicenceCollect, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.collectLicence(id, licenceCollect, options).then((request) => request(axios, basePath));
        },
        /**
         * Send Licence e-mail notice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailLicenceReady(id: number, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.emailLicenceReady(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate licence for printing
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLicence(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.generateLicence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get one licence
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenceById(id: number, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.getLicenceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Licences
         * @param {string} [from] Lower date value for Licence issuedOn property
         * @param {string} [search] Search by licenceNumber and collectedBy
         * @param {string} [to] Upper date value for Licence issuedOn property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicences(from?: string, search?: string, to?: string, options?: any): AxiosPromise<Array<LicenceGet>> {
            return localVarFp.getLicences(from, search, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Operational Strikes made against a Licence
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStrikes(search?: string, options?: any): AxiosPromise<Array<LicenceStrike>> {
            return localVarFp.getStrikes(search, options).then((request) => request(axios, basePath));
        },
        /**
         * Licence summary data
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(from?: string, to?: string, typeId?: string, options?: any): AxiosPromise<LicenceSummary> {
            return localVarFp.getSummary(from, to, typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update licence payment
         * @param {number} id 
         * @param {YesNoComment} [yesNoComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markLicenceAsPaid(id: number, yesNoComment?: YesNoComment, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.markLicenceAsPaid(id, yesNoComment, options).then((request) => request(axios, basePath));
        },
        /**
         * Update revoked status of Licence
         * @param {number} id 
         * @param {LicenceRevocation} [licenceRevocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicence(id: number, licenceRevocation?: LicenceRevocation, options?: any): AxiosPromise<LicenceGet> {
            return localVarFp.revokeLicence(id, licenceRevocation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenceControllerApi - object-oriented interface
 * @export
 * @class LicenceControllerApi
 * @extends {BaseAPI}
 */
export class LicenceControllerApi extends BaseAPI {
    /**
     * Add Operational Strike to Licence
     * @param {number} id 
     * @param {OperationalStrikePost} [operationalStrikePost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public addOperationalStrike(id: number, operationalStrikePost?: OperationalStrikePost, options?: any) {
        return LicenceControllerApiFp(this.configuration).addOperationalStrike(id, operationalStrikePost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark licence as uplifted
     * @param {number} id 
     * @param {LicenceCollect} [licenceCollect] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public collectLicence(id: number, licenceCollect?: LicenceCollect, options?: any) {
        return LicenceControllerApiFp(this.configuration).collectLicence(id, licenceCollect, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send Licence e-mail notice
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public emailLicenceReady(id: number, options?: any) {
        return LicenceControllerApiFp(this.configuration).emailLicenceReady(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate licence for printing
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public generateLicence(id: number, options?: any) {
        return LicenceControllerApiFp(this.configuration).generateLicence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get one licence
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public getLicenceById(id: number, options?: any) {
        return LicenceControllerApiFp(this.configuration).getLicenceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Licences
     * @param {string} [from] Lower date value for Licence issuedOn property
     * @param {string} [search] Search by licenceNumber and collectedBy
     * @param {string} [to] Upper date value for Licence issuedOn property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public getLicences(from?: string, search?: string, to?: string, options?: any) {
        return LicenceControllerApiFp(this.configuration).getLicences(from, search, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Operational Strikes made against a Licence
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public getStrikes(search?: string, options?: any) {
        return LicenceControllerApiFp(this.configuration).getStrikes(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Licence summary data
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [typeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public getSummary(from?: string, to?: string, typeId?: string, options?: any) {
        return LicenceControllerApiFp(this.configuration).getSummary(from, to, typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update licence payment
     * @param {number} id 
     * @param {YesNoComment} [yesNoComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public markLicenceAsPaid(id: number, yesNoComment?: YesNoComment, options?: any) {
        return LicenceControllerApiFp(this.configuration).markLicenceAsPaid(id, yesNoComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update revoked status of Licence
     * @param {number} id 
     * @param {LicenceRevocation} [licenceRevocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceControllerApi
     */
    public revokeLicence(id: number, licenceRevocation?: LicenceRevocation, options?: any) {
        return LicenceControllerApiFp(this.configuration).revokeLicence(id, licenceRevocation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenceTypeControllerApi - axios parameter creator
 * @export
 */
export const LicenceTypeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPost} [licenceClassPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLicenceClass: async (id: number, licenceClassPost?: LicenceClassPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addLicenceClass', 'id', id)
            const localVarPath = `/api/v1/licence-types/{id}/classes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenceClassPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenceTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licence-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLicenceClass: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeLicenceClass', 'id', id)
            const localVarPath = `/api/v1/licence-types/classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPut} [licenceClassPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenceClass: async (id: number, licenceClassPut?: LicenceClassPut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicenceClass', 'id', id)
            const localVarPath = `/api/v1/licence-types/classes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenceClassPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenceTypeControllerApi - functional programming interface
 * @export
 */
export const LicenceTypeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenceTypeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPost} [licenceClassPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLicenceClass(id: number, licenceClassPost?: LicenceClassPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLicenceClass(id, licenceClassPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenceTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenceTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLicenceClass(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLicenceClass(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPut} [licenceClassPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicenceClass(id: number, licenceClassPut?: LicenceClassPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicenceClass(id, licenceClassPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenceTypeControllerApi - factory interface
 * @export
 */
export const LicenceTypeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenceTypeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPost} [licenceClassPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLicenceClass(id: number, licenceClassPost?: LicenceClassPost, options?: any): AxiosPromise<LicenceType> {
            return localVarFp.addLicenceClass(id, licenceClassPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenceTypes(options?: any): AxiosPromise<Array<LicenceType>> {
            return localVarFp.getLicenceTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLicenceClass(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeLicenceClass(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LicenceClassPut} [licenceClassPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenceClass(id: number, licenceClassPut?: LicenceClassPut, options?: any): AxiosPromise<LicenceType> {
            return localVarFp.updateLicenceClass(id, licenceClassPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenceTypeControllerApi - object-oriented interface
 * @export
 * @class LicenceTypeControllerApi
 * @extends {BaseAPI}
 */
export class LicenceTypeControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {LicenceClassPost} [licenceClassPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceTypeControllerApi
     */
    public addLicenceClass(id: number, licenceClassPost?: LicenceClassPost, options?: any) {
        return LicenceTypeControllerApiFp(this.configuration).addLicenceClass(id, licenceClassPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceTypeControllerApi
     */
    public getLicenceTypes(options?: any) {
        return LicenceTypeControllerApiFp(this.configuration).getLicenceTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceTypeControllerApi
     */
    public removeLicenceClass(id: number, options?: any) {
        return LicenceTypeControllerApiFp(this.configuration).removeLicenceClass(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LicenceClassPut} [licenceClassPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceTypeControllerApi
     */
    public updateLicenceClass(id: number, licenceClassPut?: LicenceClassPut, options?: any) {
        return LicenceTypeControllerApiFp(this.configuration).updateLicenceClass(id, licenceClassPut, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportControllerApi - axios parameter creator
 * @export
 */
export const ReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParrotRegistrationReport: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports/parrot-registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (from?: string, to?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParrotRegistrationReport(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParrotRegistrationReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(from?: string, to?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(from, to, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParrotRegistrationReport(options?: any): AxiosPromise<FileLink> {
            return localVarFp.createParrotRegistrationReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(from?: string, to?: string, type?: string, options?: any): AxiosPromise<FileLink> {
            return localVarFp.createReport(from, to, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public createParrotRegistrationReport(options?: any) {
        return ReportControllerApiFp(this.configuration).createParrotRegistrationReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public createReport(from?: string, to?: string, type?: string, options?: any) {
        return ReportControllerApiFp(this.configuration).createReport(from, to, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WildlifeCategoryControllerApi - axios parameter creator
 * @export
 */
export const WildlifeCategoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WildlifeCategoryPost} [wildlifeCategoryPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory: async (wildlifeCategoryPost?: WildlifeCategoryPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wildlife-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wildlifeCategoryPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WildlifeSpeciesPost} [wildlifeSpeciesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSpecies: async (id: number, wildlifeSpeciesPost?: WildlifeSpeciesPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSpecies', 'id', id)
            const localVarPath = `/api/v1/wildlife-categories/{id}/species`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wildlifeSpeciesPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/api/v1/wildlife-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecies: async (categoryId: number, speciesId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteSpecies', 'categoryId', categoryId)
            // verify required parameter 'speciesId' is not null or undefined
            assertParamExists('deleteSpecies', 'speciesId', speciesId)
            const localVarPath = `/api/v1/wildlife-categories/{categoryId}/species/{speciesId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"speciesId"}}`, encodeURIComponent(String(speciesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wildlife-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategoryById', 'id', id)
            const localVarPath = `/api/v1/wildlife-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {WildlifeSpeciesPut} [wildlifeSpeciesPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecies: async (categoryId: number, speciesId: number, wildlifeSpeciesPut?: WildlifeSpeciesPut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('updateSpecies', 'categoryId', categoryId)
            // verify required parameter 'speciesId' is not null or undefined
            assertParamExists('updateSpecies', 'speciesId', speciesId)
            const localVarPath = `/api/v1/wildlife-categories/{categoryId}/species/{speciesId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"speciesId"}}`, encodeURIComponent(String(speciesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "SecurityScheme", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wildlifeSpeciesPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WildlifeCategoryControllerApi - functional programming interface
 * @export
 */
export const WildlifeCategoryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WildlifeCategoryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WildlifeCategoryPost} [wildlifeCategoryPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCategory(wildlifeCategoryPost?: WildlifeCategoryPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WildlifeCategoryGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCategory(wildlifeCategoryPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {WildlifeSpeciesPost} [wildlifeSpeciesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSpecies(id: number, wildlifeSpeciesPost?: WildlifeSpeciesPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WildlifeCategoryGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSpecies(id, wildlifeSpeciesPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSpecies(categoryId: number, speciesId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSpecies(categoryId, speciesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WildlifeCategoryGet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WildlifeCategoryGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {WildlifeSpeciesPut} [wildlifeSpeciesPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpecies(categoryId: number, speciesId: number, wildlifeSpeciesPut?: WildlifeSpeciesPut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WildlifeCategoryGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpecies(categoryId, speciesId, wildlifeSpeciesPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WildlifeCategoryControllerApi - factory interface
 * @export
 */
export const WildlifeCategoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WildlifeCategoryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {WildlifeCategoryPost} [wildlifeCategoryPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory(wildlifeCategoryPost?: WildlifeCategoryPost, options?: any): AxiosPromise<WildlifeCategoryGet> {
            return localVarFp.addCategory(wildlifeCategoryPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WildlifeSpeciesPost} [wildlifeSpeciesPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSpecies(id: number, wildlifeSpeciesPost?: WildlifeSpeciesPost, options?: any): AxiosPromise<WildlifeCategoryGet> {
            return localVarFp.addSpecies(id, wildlifeSpeciesPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecies(categoryId: number, speciesId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSpecies(categoryId, speciesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): AxiosPromise<Array<WildlifeCategoryGet>> {
            return localVarFp.getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(id: number, options?: any): AxiosPromise<WildlifeCategoryGet> {
            return localVarFp.getCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} speciesId 
         * @param {WildlifeSpeciesPut} [wildlifeSpeciesPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecies(categoryId: number, speciesId: number, wildlifeSpeciesPut?: WildlifeSpeciesPut, options?: any): AxiosPromise<WildlifeCategoryGet> {
            return localVarFp.updateSpecies(categoryId, speciesId, wildlifeSpeciesPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WildlifeCategoryControllerApi - object-oriented interface
 * @export
 * @class WildlifeCategoryControllerApi
 * @extends {BaseAPI}
 */
export class WildlifeCategoryControllerApi extends BaseAPI {
    /**
     * 
     * @param {WildlifeCategoryPost} [wildlifeCategoryPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public addCategory(wildlifeCategoryPost?: WildlifeCategoryPost, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).addCategory(wildlifeCategoryPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WildlifeSpeciesPost} [wildlifeSpeciesPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public addSpecies(id: number, wildlifeSpeciesPost?: WildlifeSpeciesPost, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).addSpecies(id, wildlifeSpeciesPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public deleteCategory(id: number, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {number} speciesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public deleteSpecies(categoryId: number, speciesId: number, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).deleteSpecies(categoryId, speciesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public getCategories(options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public getCategoryById(id: number, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).getCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {number} speciesId 
     * @param {WildlifeSpeciesPut} [wildlifeSpeciesPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WildlifeCategoryControllerApi
     */
    public updateSpecies(categoryId: number, speciesId: number, wildlifeSpeciesPut?: WildlifeSpeciesPut, options?: any) {
        return WildlifeCategoryControllerApiFp(this.configuration).updateSpecies(categoryId, speciesId, wildlifeSpeciesPut, options).then((request) => request(this.axios, this.basePath));
    }
}


