import { VuexModule } from "vuex-module-decorators";

import { Module, Mutation, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ApplicationGet, PartialApplicationGet } from "@/api/lms";

@Module({
  name: "licenceApplication",
  dynamic: true,
  store,
})
export default class LicenceApplication extends VuexModule {
  applications: PartialApplicationGet[] = [];

  get isEmpty() {
    return this.applications.length == 0;
  }

  @Mutation
  setApplications(applications: PartialApplicationGet[]) {
    this.applications = applications;
  }

  @Mutation
  updateApplication(newApp: ApplicationGet) {
    this.applications = this.applications.map((app) => {
      if (app.id == newApp.id) return newApp;
      return app;
    });
  }

  @Mutation
  deleteAppication(oldApp: ApplicationGet) {
    this.applications = this.applications.filter((app) => oldApp.id != app.id);
  }
}

export const applicationStore = getModule(LicenceApplication);
