




















































import { keycloakStore } from "@/store/modules/KeycloakStore";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class extends Vue {
  get keycloakAccount() {
    return process.env.VUE_APP_KEYCLOAK_ACCOUNT;
  }

  get signOutURL() {
    return keycloakStore.keycloak.createLogoutUrl();
  }

  get name() {
    if (keycloakStore.keycloak.token) {
      const about = keycloakStore.keycloak["idTokenParsed"];
      return about["name"];
    }
    return "";
  }

  get email() {
    if (keycloakStore.keycloak.token) {
      const about = keycloakStore.keycloak["idTokenParsed"];
      return about["email"];
    }
    return "";
  }

  get roles() {
    if (keycloakStore.keycloak.token) {
      const resourceServer =
        keycloakStore.keycloak["resourceAccess"]["dlms-api"];
      const userRoles = resourceServer ? resourceServer["roles"] : [];
      keycloakStore.setRoles(userRoles);
      return resourceServer
        ? userRoles.map((role: any) => role.toUpperCase())
        : [];
    }
    return [];
  }
}
