























































































































































import { LicenceControllerApi, LicenceGet } from "@/api/lms";
import axios from "../axios";
import { manageLoading } from "@/utils/SnackbarUtil";
import { licenceStore } from "@/store/modules/LicenceStore";
import Vue from "vue";
import Component from "vue-class-component";
import { BASE_PATH } from "@/api/lms/base";

interface licenceFilter {
  startDate: string;
  startDateShow: boolean;
  endDate: string;
  endDateShow: boolean;
  search: string;
}

@Component
export default class Licence extends Vue {
  api = new LicenceControllerApi(undefined, BASE_PATH, axios);
  headers = [
    { text: "Actions", value: "actions" },
    { text: "First Name", value: "firstName" },
    { text: "Last Name", value: "lastName" },
    { text: "Licence #", value: "licenceNumber" },
    { text: "Licence Type", value: "licenceType" },
    { text: "Licence Class", value: "licenceClass" },
    { text: "Issued On", value: "issuedOn" },
    { text: "Expires On", value: "expiresOn" },
    { text: "Paid", value: "paid" },
    { text: "Collected", value: "collected" },
    { text: "Collected By", value: "collectedBy" },
    { text: "Collected At", value: "collectedAt" },
    { text: "Email Sent", value: "emailSent" },
    { text: "Revoked", value: "revoked" },
  ];

  search = "";

  licenceFilter: licenceFilter = this.initlicenceFilter();

  get licences() {
    return licenceStore.licences;
  }

  initlicenceFilter() {
    const currentDate = new Date();
    currentDate.setDate(1);
    return {
      startDate: currentDate.toISOString().split("T")[0],
      startDateShow: false,
      endDate: "",
      endDateShow: false,
      search: "",
    };
  }

  mounted() {
    if (licenceStore.licences.length == 0) {
      this.refresh();
    }
  }

  refresh() {
    manageLoading("Loading Licences", async () => {
      if (this.licenceFilter.startDate == null)
        this.licenceFilter.startDate = "";
      if (this.licenceFilter.endDate == null) this.licenceFilter.endDate = "";
      if (this.licenceFilter.search == null) this.licenceFilter.search = "";

      const response = await this.api.getLicences(
        this.licenceFilter.startDate,
        this.licenceFilter.search,
        this.licenceFilter.endDate
      );
      licenceStore.setLicences(response.data);
    });
  }
}
