












































































import { AnimalControllerApi, ParrotGet, ParrotPost } from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

interface CommonName {
  commonName: string;
}

@Component
export default class ParrotManager extends Vue {
  api = new AnimalControllerApi();

  headers = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Common Name", value: "commonName" },
  ];

  parrorModel: ParrotGet = this.initParrotModel();

  commonNames: CommonName[] = [];
  commonName = "";

  isNew = true;

  initParrotModel() {
    return {
      id: 0,
      scientificName: "",
      description: "",
      commonNames: [],
      photo: "",
    };
  }

  mounted() {
    const id = this.$route.params.id;
    if (!id) return;
    this.isNew = false;
    manageLoading("Loading Parrot Information", async () => {
      const response = await this.api.getParrotById(Number(id));
      this.parrorModel = response.data;
      if (this.parrorModel.commonNames)
        this.commonNames = this.parrorModel.commonNames.map((name) => ({
          commonName: name,
        }));
    });
  }

  create() {
    const form: any = this.$refs["form"];
    if (!form.validate()) return;
    manageLoading("Creating Parrot", async () => {
      const { id, ...parrot } = { ...this.parrorModel };
      parrot.commonNames = this.commonNames.map((name) => name.commonName);
      await this.api.addParrot(parrot);
      this.parrorModel = this.initParrotModel();
      this.commonNames = [];
    });
  }

  update() {
    manageLoading("Updating Parrot", async () => {
      const { id, ...parrot } = { ...this.parrorModel };
      parrot.commonNames = this.commonNames.map((name) => name.commonName);
      await this.api.updateParrotById(Number(this.parrorModel.id), parrot);
    });
  }

  deleteParrot() {
    manageLoading("Deleting Parrot", async () => {
      await this.api.deleteParrotById(Number(this.parrorModel.id));
      this.$router.push("/parrots");
    });
  }

  addCommonName() {
    this.commonNames.push({ commonName: this.commonName });
    this.commonName = "";
  }

  removeCommonName(item: CommonName) {
    this.commonNames = this.commonNames.filter(
      (name) => name.commonName != item.commonName
    );
  }

  onPhotoInput(file: File | null) {
    if (file === null) return;

    const reader = new FileReader();
    reader.onload = () =>
      (this.parrorModel.photo = String(reader.result).split(",")[1]);
    reader.readAsDataURL(file);
  }
}
