

















































































import {
  WildlifeCategoryControllerApi,
  WildlifeCategoryGet,
  WildlifeCategoryPost,
} from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { keycloakStore } from "@/store/modules/KeycloakStore";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class WildlifeCategories extends Vue {
  api = new WildlifeCategoryControllerApi();

  categories: WildlifeCategoryGet[] = [];

  categoryPost: WildlifeCategoryPost = {
    category: "",
  };

  search = "";

  showCreate = false;

  get headers() {
    let actions = [];
    if (keycloakStore.isLicenceOfficer)
      actions.push({ text: "Actions", value: "actions", sortable: false });

    const details = [
      { text: "Category", value: "category", sortable: true },
      { text: "Species Count", value: "species", sortable: true },
    ];
    const columns = actions.concat(details);
    return columns;
  }

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  mounted() {
    this.refresh();
  }

  refresh() {
    manageLoading("Loading Wildife Categories", async () => {
      const response = await this.api.getCategories();
      this.categories = response.data;
    });
  }

  createCategory() {
    manageLoading("Creating Wildlife Category", async () => {
      await this.api.addCategory(this.categoryPost);
      await this.refresh();
    });
  }
}
