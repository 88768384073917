









































import { AnimalControllerApi, ParrotGet } from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Parrot extends Vue {
  headers = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Scientific Name", value: "scientificName" },
    { text: "Common Names", value: "commonNames" },
    { text: "Photo", value: "photo" },
  ];

  items: ParrotGet[] = [];
  search = "";

  api = new AnimalControllerApi();

  mounted() {
    manageLoading("Loading Parrot", async () => {
      const response = await this.api.getParrots();
      this.items = response.data;
    });
  }
}
