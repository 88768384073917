































import { Vue, Component } from "vue-property-decorator";
import { MessageConfig, snackbars } from "@/store/modules/snackbars";

@Component
export default class SnackBar extends Vue {
  get messageConfigs() {
    return snackbars.messageConfigs;
  }

  private removeMessage(messageConfig: MessageConfig) {
    snackbars.removeMessage(messageConfig);
  }
}
