






















import Vue from "vue";
import Component from "vue-class-component";

import ParrotReport from "@/components/reports/ParrotReport.vue";
import ApplicationReport from "@/components/reports/ApplicationReport.vue";
import LicenceReport from "@/components/reports/LicenceReport.vue";
import { keycloakStore } from "@/store/modules/KeycloakStore";

@Component({
  components: {
    ParrotReport,
    ApplicationReport,
    LicenceReport,
  },
})
export default class Reports extends Vue {
  tab = null;

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  get parrotManager() {
    return keycloakStore.isParrotManager;
  }
}
