






















































































































































































































































import {
  AnimalControllerApi,
  FileControllerApi,
  ParrotRegisterGet,
  ParrotRegisterPost,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

import FileViewer from "@/components/FileViewer.vue";

@Component({
  components: {
    FileViewer,
  },
})
export default class ParrotRegister extends Vue {
  api = new AnimalControllerApi();
  fileApi = new FileControllerApi();

  form: ParrotRegisterGet | null = null;

  mounted() {
    manageLoading("Loading Parrot Registration", async () => {
      const id = Number(this.$route.params.id);
      const response = await this.api.getRegisteredParrotById(id);
      this.form = response.data;
    });
  }
}
