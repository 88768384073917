




import Vue from "vue";
import Component from "vue-class-component";

import Licence from "@/components/Licence.vue";

@Component({
  components: {
    Licence,
  },
})
export default class Licences extends Vue {}
