



































































































































import {
  ApplicationControllerApi,
  ApplicationGet,
  CategoryGrantPost,
  CategoryRequestPost,
  HarvestAreaPost,
  LicencePost,
} from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { applicationStore } from "@/store/modules/LicenceApplication";
import { manageLoading } from "@/utils/SnackbarUtil";
import { minLength } from "@/utils/Validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { BASE_PATH } from "@/api/lms/base";
import { licenceStore } from "@/store/modules/LicenceStore";

@Component
export default class LicenceApplicationIssuer extends Vue {
  @Prop(Object) readonly input: ApplicationGet | undefined;
  categoryHeaders = [
    { text: "Actions", value: "actions" },
    { text: "Region", value: "region" },
    { text: "Area", value: "area" },
    { text: "Category", value: "category" },
    { text: "Unknown Category", value: "unknownCategory" },
    { text: "Species", value: "species" },
  ];

  harvestAreaHeaders = [
    { text: "Actions", value: "actions" },
    { text: "Region", value: "region" },
    { text: "Area/Village", value: "area" },
  ];

  licence: LicencePost = {
    categories: [],
    issuedOn: "",
    expiresOn: "",
    licenceClass: 0,
  };

  app: ApplicationGet | null = null;
  api = new ApplicationControllerApi();

  search = "";

  issuedDateShow = false;
  expiredDateShow = false;
  licenceIssued = false;
  selectedLicenceType = "";

  @Watch("input")
  oncAppChanged(newApp: ApplicationGet) {
    this.app = newApp;
    this.licence.categories = [];
    newApp.categories?.forEach((cat) =>
      newApp.harvestAreas?.forEach((ha) =>
        this.licence.categories?.push(this.toCategoryGrant(ha, cat))
      )
    );
    this.licenceIssued = Number(this.app.licenceId) > 0;
    this.selectedLicenceType = this.app.licenceType;
  }

  get licenceClasses() {
    if (this.app)
      return licenceStore.licenceTypes
        .filter((type) => type.name == this.selectedLicenceType)
        .flatMap((type) => type.classes);
    else return [];
  }

  get licenceTypes() {
    return licenceStore.licenceTypes.map((type) => type.name);
  }

  issueLicence() {
    const form: any = this.$refs["form"];
    if (!form.validate()) return;
    showLoadingDialog(
      DialogType.SUCCESS,
      "Creating Licence",
      "Please confirm all entered data is accurate before proceeding. This action cannot be undone or modified.",
      async () => {
        const id = Number(this.app?.id);
        const form = new FormData();
        const response = await this.api.addLicence(id, this.licence);
        if (this.app) {
          this.app.licenceId = response.data.id;
          this.licenceIssued = true;
          applicationStore.updateApplication(this.app);
        }
      }
    );
  }

  setLicenceDates() {
    const selectedClass = this.licenceClasses.find(
      (item) => item?.id == this.licence.licenceClass
    );
    if (!selectedClass) return;
    const today = new Date();
    this.licence.issuedOn = today.toISOString().substring(0, 10);
    today.setDate(today.getDate() + selectedClass?.recommendedValidDays);
    this.licence.expiresOn = today.toISOString().substring(0, 10);
  }

  removeCategoryItem(item: CategoryGrantPost) {
    if (!this.licence.categories) return;
    var count = 0;
    this.licence.categories = this.licence.categories.filter((cat) => {
      if (
        item.category == cat.category &&
        item.species == cat.species &&
        item.region == cat.region &&
        item.area == cat.area &&
        count == 0
      ) {
        count++;
        return false;
      }
      return true;
    });
  }

  size(input: string, min: number) {
    return minLength(input, min);
  }

  toCategoryGrant(
    ha: HarvestAreaPost,
    catRequest: CategoryRequestPost
  ): CategoryGrantPost {
    return {
      ...catRequest,
      region: Number(ha.region),
      area: ha.area,
    };
  }
}
