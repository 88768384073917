import axios from "axios";
import { keycloakStore } from "@/store/modules/KeycloakStore";

axios.interceptors.request.use((requestConfig) => {
  const token = keycloakStore.keycloak.token;
  requestConfig.headers["Authorization"] = token
    ? `Bearer ${token}`
    : undefined;
  return requestConfig;
});

export default axios;
