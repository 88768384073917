











































import {
  ApplicationControllerApi,
  ApplicationGet,
  LicenceControllerApi,
  LicenceStrike,
  OperationalStrikeGet,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class OperationalStrikes extends Vue {
  headers = [
    { text: "First Name", value: "firstName", sortable: false },
    { text: "Last Name", value: "lastName", sortable: false },
    { text: "Licence #", value: "licenceNumber", sortable: false },
    { text: "Id Type", value: "idType", sortable: false },
    { text: "Id #", value: "idNumber", sortable: false },
    { text: "TIN", value: "tin", sortable: false },
    { text: "Business Name", value: "businessName", sortable: false },
    {
      text: "Business Registration #",
      value: "businessRegistrationNumber",
      sortable: false,
    },
    {
      text: "Incorporation #",
      value: "certificateOfIncorportationNumber",
      sortable: false,
    },
  ];

  licences: LicenceStrike[] = [];

  expanded: ApplicationGet[] = [];

  search = "";
  filter = "";

  api = new LicenceControllerApi();

  find() {
    manageLoading("Loading Applications", async () => {
      const response = await this.api.getStrikes(this.filter);
      this.licences = response.data;
    });
  }
}
