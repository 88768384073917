













































































































































































import {
  ApplicationControllerApi,
  ApplicationGet,
  IdentificationType,
  LicenceClassPut,
  LicenceCollect,
  LicenceControllerApi,
  LicenceGet,
} from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { licenceStore } from "@/store/modules/LicenceStore";
import { manageLoading } from "@/utils/SnackbarUtil";
import { minLength } from "@/utils/Validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import FileViewer from "@/components/FileViewer.vue";
import { keycloakStore } from "@/store/modules/KeycloakStore";

@Component({
  components: {
    FileViewer,
  },
})
export default class LicenceManager extends Vue {
  @Prop(Object) input: LicenceGet | undefined;
  @Prop(Object) app: ApplicationGet | undefined;

  licence: LicenceGet | undefined = {
    id: 0,
    licenceNumber: "",
    categories: [],
    application_id: 0,
    collected: false,
    collectedBy: "",
    collectedAt: "",
    createdAt: "",
    createdBy: "",
    expiresOn: "",
    firstName: "",
    lastName: "",
    idNumber: "",
    idType: IdentificationType.IdentificationCard,
    issuedOn: "",
    licenceClass: "",
    licenceType: "",
    paid: false,
    proofOfLicence: "",
    revoked: false,
  };

  api = new LicenceControllerApi();

  operationalStrike = "";

  dialogMessage = "Are you sure you want to complete this aciton?";

  showPrint = false
  licencesToPrint: string[] = []

  @Watch("app")
  onWatchChanged() {
    this.licence = this.input;
    this.updateCollectedBy();
  }

  get deskStaff() {
    return keycloakStore.isDeskStaff;
  }

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  get collected() {
    return this.licence?.collected;
  }

  get id() {
    return Number(this.licence?.id);
  }

  updateCollectedBy() {
    if (this.licence?.collected == false) {
      this.licence.collectedBy = this.app?.forBusiness
        ? String(this.app.businessName)
        : `${this.licence.firstName} ${this.licence.lastName}`;
    }
  }

  uplift() {
    const form: any = this.$refs.uplift;
    if (!form.validate()) return;
    showLoadingDialog(
      DialogType.SUCCESS,
      "Marking Licence as Uplifted",
      this.dialogMessage + " . This action cannot be undone or modified.",
      async () => {
        const collect = { collectedBy: String(this.licence?.collectedBy) };
        const response = await this.api.collectLicence(this.id, collect);
        this.licence = response.data;
        this.updateCollectedBy();
        licenceStore.updateLicence(this.licence);
      }
    );
  }

  sendEmail() {
    showLoadingDialog(
      DialogType.SUCCESS,
      "Sending Licence Email Notice",
      this.dialogMessage,
      async () => {
        const response = await this.api.emailLicenceReady(this.id);
        this.licence = response.data;
        this.updateCollectedBy();
        licenceStore.updateLicence(this.licence);
      }
    );
  }

  submitOperationalStrike() {
    const form: any = this.$refs.strike;
    if (!form.validate()) return;
    showLoadingDialog(
      DialogType.SUCCESS,
      "Adding Operational Strike",
      this.dialogMessage,
      async () => {
        const response = await this.api.addOperationalStrike(this.id, {
          description: this.operationalStrike,
        });
        this.licence = response.data;
        this.updateCollectedBy();
        licenceStore.updateLicence(this.licence);
      }
    );
  }

  revokeLicence() {
    if (!this.licence) return;
    showLoadingDialog(
      this.licence.revoked ? DialogType.SUCCESS : DialogType.ERROR,
      "Updating Revocation Status",
      this.dialogMessage,
      async () => {
        const response = await this.api.revokeLicence(this.id, {
          revoked: !this.licence?.revoked,
        });
        this.licence = response.data;
        this.updateCollectedBy();
        licenceStore.updateLicence(this.licence);
      }
    );
  }

  markAsPaid() {
    if (!this.licence) return;
    showLoadingDialog(
      this.licence.paid ? DialogType.ERROR : DialogType.SUCCESS,
      "Updating Payment Status",
      this.dialogMessage,
      async () => {
        const response = await this.api.markLicenceAsPaid(this.id, {
          yesNo: !this.licence?.paid,
        });
        this.licence = response.data;
        this.updateCollectedBy();
        licenceStore.updateLicence(response.data);
      }
    );
  }

  print() {
    if (!this.licence) return;
    showLoadingDialog(
      this.licence.paid ? DialogType.ERROR : DialogType.SUCCESS,
      "Generating Licence Document",
      this.dialogMessage,
      async () => {
        const response = await this.api.generateLicence(this.id, {
          yesNo: !this.licence?.paid,
        });
        this.licencesToPrint = response.data;
        this.showPrint = true
      }
    );
  }

  printLicence(document: string) {
    const wnd = window.open("about:blank", "", "_blank");
    wnd?.document.write(String(document));
  }

  min(input: string, min: number) {
    return minLength(input, min);
  }
}
