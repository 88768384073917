










import Vue from "vue";
import Component from "vue-class-component";
import ParrotManager from "@/components/ParrotManager.vue";

@Component({
  components: {
    ParrotManager,
  },
})
export default class ParrotManagement extends Vue {}
