













































































import {
  AnimalControllerApi,
  FileControllerApi,
  ParrotRegistrationSummary,
  ParrotType,
  ReportControllerApi,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

import BarChart from "@/components/charts/BarChart.vue";
import PolarAreaChart from "@/components/charts/PolarAreaChart.vue";
import { randomColour, randomColours } from "@/utils/ColourUtil.ts";

@Component({
  components: {
    BarChart,
    PolarAreaChart,
  },
})
export default class ParrotReport extends Vue {
  api = new AnimalControllerApi();
  reportApi = new ReportControllerApi();
  fileApi = new FileControllerApi();

  summaries: ParrotRegistrationSummary[] = [];
  parrotNames: string[] = [];

  all = "All";

  byRegionData: any = {};
  regionDataFilter = this.all;
  regionColours: string[] = [];

  byParrotData: any = {};
  parrotDataFilter = this.all;
  parrotColours: string[] = [];

  stackedByRegionData: any = {};

  stackedBarChartOptions = {
    scales: {
      yAxes: [
        {
          stacked: true,
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  reports = ["Parrot Registration"];
  report = "";

  loaded = false;
  exportLoader = false;

  mounted() {
    manageLoading("Loading Parrot Summary", async () => {
      const response = await this.api.getRegisteredParrotSummary();
      this.summaries = response.data;

      this.byRegion();
      this.byParrot();
      this.stackedByRegion();

      this.loaded = true;
    });
  }

  byParrot() {
    const data: any = {};
    this.summaries
      .filter(
        (sum) =>
          this.parrotDataFilter == this.all ||
          Number(this.parrotDataFilter) == sum.region
      )
      .flatMap((sum) => sum.types)
      .forEach((type) => {
        const key = String(type?.name);
        if (data[key] === undefined) data[key] = 0;
        data[key] = data[key] + type?.count;
      });

    this.parrotNames = Object.keys(data);

    if (this.parrotColours.length == 0)
      this.parrotColours = randomColours(this.parrotNames.length);

    const counts = Object.values(data);
    const labels = Object.entries(data).map((en) => `${en[0]}(${en[1]})`);
    this.byParrotData = {
      labels: labels,
      datasets: [
        {
          data: counts,
          backgroundColor: this.parrotColours,
          borderWidth: 2,
        },
      ],
    };
  }

  byRegion() {
    const regionData = this.summaries
      .map((sum) => ({
        [Number(sum.region)]: sum.types
          ?.filter(
            (type) =>
              this.regionDataFilter == this.all ||
              this.regionDataFilter == type.name
          )
          .map((type) => type.count)
          .reduce((prev, curr) => Number(prev) + Number(curr), 0),
      }))
      .reduce((prev, cur) => ({ ...prev, ...cur }), []);

    if (this.regionColours.length == 0) this.regionColours = randomColours(10);

    this.byRegionData = {
      labels: Object.keys(regionData).map((x) => `Region ${x}`),
      datasets: [
        {
          label: this.regionDataFilter,
          data: Object.values(regionData),
          backgroundColor: this.regionColours,
          borderWidth: 2,
        },
      ],
    };
  }

  stackedByRegion() {
    const parrotData: any = {};

    this.summaries.forEach((regionSum) => {
      regionSum.types?.forEach((type) => {
        const key = String(type?.name);
        if (parrotData[key] === undefined)
          parrotData[key] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const regionCount = parrotData[key][Number(regionSum.region) - 1];
        parrotData[key][Number(regionSum.region) - 1] =
          regionCount + type.count;
      });
    });

    const parrots = Object.keys(parrotData);
    const colours = parrots
      .map((parrot) => ({ [parrot]: randomColour() }))
      .reduce((prev, cur) => ({ ...prev, ...cur }), []);

    this.stackedByRegionData = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => `Region ${i}`),
      datasets: parrots.map((parrot: any) => ({
        data: parrotData[parrot],
        label: parrot,
        backgroundColor: colours[parrot],
      })),
    };
  }

  async createReport() {
    if (this.report != this.reports[0]) return;
    this.exportLoader = true;
    try {
      const response = await this.reportApi.createParrotRegistrationReport();
      if (confirm("Click OK to download report")) open(response.data.link);
    } catch (e) {
      console.log(e);
    } finally {
      this.exportLoader = false;
    }
  }
}
