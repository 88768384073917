import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Keycloak from "keycloak-js";
import { keycloakStore } from "@/store/modules/KeycloakStore";
import { licenceStore } from "./store/modules/LicenceStore";
import { LicenceTypeControllerApi } from "./api/lms";

Vue.config.productionTip = false;

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_AUTH,
  realm: "gwcmc-dlms",
  clientId: "dlms-lap",
  onLoad: "login-required",
};

const keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: "login-required" })
  .then(async (auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      keycloakStore.setKeycloak(keycloak);
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
      const api = new LicenceTypeControllerApi();
      const response = await api.getLicenceTypes();
      licenceStore.setLicenceTypes(response.data);
    }

    // Refresh Token
    setInterval(() => {
      keycloak.updateToken(70);
    }, 6000);
  })
  .catch(() => {
    console.log("Authentication Error");
  });
