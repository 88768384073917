


























import Vue from "vue";

import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Account from "@/components/Account.vue";
import { keycloakStore } from "@/store/modules/KeycloakStore";

interface Link {
  title: string;
  icon: string;
  path: string;
}

@Component({
  components: {
    Account,
  },
})
export default class NavigationDrawer extends Vue {
  @Prop({ default: false, type: Boolean }) readonly mini: boolean | undefined;

  get links() {
    const licenceStaff = [
      {
        title: "LICENCE APPLICATIONS",
        icon: "mdi-text-box",
        path: "/",
      },
      {
        title: "APPROVED LICENCES",
        icon: "mdi-text-box-check",
        path: "/licences",
      },
      {
        title: "LICENCE TYPES",
        icon: "mdi-format-list-bulleted-type",
        path: "/licence-types",
      },
      {
        title: "WILDLIFE CATEGORIES",
        icon: "mdi-dog-side",
        path: "/wildlife-categories",
      },
      {
        title: "OPERATIONAL STRIKES",
        icon: "mdi-close-thick",
        path: "/strikes",
      },
    ];

    const parrotManager = [
      {
        title: "PARROT REGISTRATIONS",
        icon: "mdi-bird",
        path: "/parrot-registrations",
      },
      {
        title: "PARROTS",
        icon: "mdi-bird",
        path: "/parrots",
      },
    ];

    const reports = [
      { title: "REPORTS", icon: "mdi-file-chart", path: "/reports" },
    ];

    let roles: Link[] = [];

    if (keycloakStore.isLicenceOfficer || keycloakStore.isDeskStaff)
      roles = roles.concat(licenceStaff);
    if (keycloakStore.isParrotManager) roles = roles.concat(parrotManager);
    if (keycloakStore.isLicenceOfficer || keycloakStore.isParrotManager)
      roles = roles.concat(reports);
    return roles;
  }

  close() {
    this.$emit("close");
  }
}
