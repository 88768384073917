






















































import {
  LicenceClassPost,
  LicenceClassPut,
  LicenceType,
  LicenceTypeControllerApi,
} from "@/api/lms";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LicenceClassUpdateForm extends Vue {
  @Prop(Object) input: LicenceClassPut | undefined;
  api = new LicenceTypeControllerApi();

  update() {
    this.$emit("update", { ...this.input });
  }

  close() {
    this.$emit("close");
  }
}
