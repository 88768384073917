
























import Vue from "vue";
import Component from "vue-class-component";
import { dialogStore, DialogType } from "@/store/modules/DialogStore";

@Component
export default class Dialog extends Vue {
  show = false;
  colour = "success";

  get message() {
    const nextMessage = dialogStore.nextMessage;
    if (nextMessage != null) {
      this.colour =
        nextMessage.type == DialogType.SUCCESS ? "success" : "error";
      this.show = true;
    } else this.show = false;
    return nextMessage;
  }

  close() {
    this.show = false;
  }

  process() {
    this.message?.onSuccess();
    this.close();
  }
}
