import { VuexModule } from "vuex-module-decorators";

import { Module, Mutation, getModule } from "vuex-module-decorators";
import store from "@/store";
import { LicenceGet, LicenceType } from "@/api/lms";

@Module({
  name: "licence",
  dynamic: true,
  store,
})
export default class LicenceStore extends VuexModule {
  licences: LicenceGet[] = [];
  licenceTypes: LicenceType[] = [];

  @Mutation
  setLicences(licences: LicenceGet[]) {
    this.licences = licences;
  }

  @Mutation
  updateLicence(newItem: LicenceGet) {
    this.licences = this.licences.map((old) => {
      if (old.id == newItem.id) return newItem;
      return old;
    });
  }

  @Mutation
  setLicenceTypes(types: LicenceType[]) {
    this.licenceTypes = types;
  }
}

export const licenceStore = getModule(LicenceStore);
