




import Vue from "vue";
import Component from "vue-class-component";

import ParrotRegistration from "@/components/ParrotRegistration.vue";

@Component({
  components: {
    ParrotRegistration,
  },
})
export default class ParrotRegisters extends Vue {}
