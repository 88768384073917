








































































import {
  WildlifeCategoryGet,
  WildlifeSpeciesGet,
  WildlifeSpeciesPost,
} from "@/api/lms";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface CommonName {
  commonName: string;
}

@Component
export default class WildlifeSpeciesManager extends Vue {
  @Prop(Object) readonly input: WildlifeSpeciesGet | undefined;

  species: WildlifeSpeciesPost = this.initialSpecies();

  headers = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Common Names", value: "commonName", sortable: false },
  ];
  commonNames: CommonName[] = [];
  commonName = "";

  @Watch("input", { immediate: true, deep: true })
  updateInput(newValue: WildlifeSpeciesGet) {
    if (newValue) {
      this.cancel(newValue);
    } else this.species = this.initialSpecies();
  }

  initialSpecies() {
    this.commonNames = [];
    this.commonName = "";
    return {
      scientificName: "",
      commonNames: [],
      description: "",
      available: false,
    };
  }

  addCommonName() {
    if (this.commonName.trim().length == 0) return;
    this.commonNames.push({ commonName: this.commonName });
    this.commonName = "";
  }

  removeCommonName(name: string) {
    this.commonNames = this.commonNames.filter(
      (item) => item.commonName != name
    );
  }

  create() {
    this.completeSpecies();
    this.$emit("create", { ...this.species });
  }

  update() {
    this.completeSpecies();
    this.$emit("update", { ...this.species });
  }

  completeSpecies() {
    this.species.commonNames = this.commonNames.map((item) => item.commonName);
  }

  cancel(item: WildlifeSpeciesGet) {
    this.clear();
    const { id, ...species } = item;
    this.species = species;
    this.commonNames = this.species.commonNames.map((item) => ({
      commonName: item,
    }));
  }

  clear() {
    this.species = this.initialSpecies();
  }

  close() {
    this.$emit("close");
  }
}
