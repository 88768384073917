var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-4 elevation-2",staticStyle:{"background-color":"white"}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Issued On Start","prepend-icon":"mdi-calendar","readonly":"","filled":"","clearable":""},model:{value:(_vm.licenceFilter.startDate),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "startDate", $$v)},expression:"licenceFilter.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.licenceFilter.startDateShow),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "startDateShow", $$v)},expression:"licenceFilter.startDateShow"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.licenceFilter.startDateShow = false}},model:{value:(_vm.licenceFilter.startDate),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "startDate", $$v)},expression:"licenceFilter.startDate"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Issued On End","prepend-icon":"mdi-calendar","readonly":"","filled":"","clearable":""},model:{value:(_vm.licenceFilter.endDate),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "endDate", $$v)},expression:"licenceFilter.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.licenceFilter.endDateShow),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "endDateShow", $$v)},expression:"licenceFilter.endDateShow"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.licenceFilter.endDateShow = false}},model:{value:(_vm.licenceFilter.endDate),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "endDate", $$v)},expression:"licenceFilter.endDate"}})],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search - [Licence #, Collected By]","filled":"","clearable":""},model:{value:(_vm.licenceFilter.search),callback:function ($$v) {_vm.$set(_vm.licenceFilter, "search", $$v)},expression:"licenceFilter.search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"x-large":""},on:{"click":_vm.refresh}},[_vm._v(" filter ")])],1)],1),_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.licences,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","to":'/licence-management/' + item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Manage")])])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")])]}},{key:"item.collected",fn:function(ref){
var item = ref.item;
return [(item.collected == undefined)?_c('v-icon',[_vm._v("mdi-minus-thick")]):(item.collected)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")])]}},{key:"item.emailSent",fn:function(ref){
var item = ref.item;
return [(item.emailSent == undefined)?_c('v-icon',[_vm._v("mdi-minus-thick")]):(item.emailSent)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")])]}},{key:"item.revoked",fn:function(ref){
var item = ref.item;
return [(item.revoked)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-close-thick")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }