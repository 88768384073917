



































































































































import { licenceStore } from "@/store/modules/LicenceStore";
import Vue from "vue";
import Component from "vue-class-component";
import { manageLoading } from "@/utils/SnackbarUtil";
import { LicenceControllerApi, LicenceGet } from "@/api/lms";
import { Prop } from "vue-property-decorator";

@Component
export default class LicenceForm extends Vue {
  @Prop(Object) licence: LicenceGet | undefined;
  api = new LicenceControllerApi();
}
