





































import Vue from "vue";

import NavigationDrawer from "@/components/NavigationDrawer.vue";
import PageHeader from "@/components/PageHeader.vue";
import Snackbars from "@/components/Snackbars.vue";
import Dialog from "@/components/Dialog.vue";

export default Vue.extend({
  name: "App",
  components: {
    NavigationDrawer,
    PageHeader,
    Snackbars,
    Dialog,
  },
  data: () => ({
    drawer: false,
    mini: true,
  }),
});
