












































































import { LicenceClassPut, LicenceTypeControllerApi } from "@/api/lms";
import { DialogType, showLoadingDialog } from "@/store/modules/DialogStore";
import { licenceStore } from "@/store/modules/LicenceStore";
import { manageLoading } from "@/utils/SnackbarUtil";
import Vue from "vue";
import Component from "vue-class-component";

import LicenceClassForm from "@/components/LicenceClassForm.vue";
import LicenceClassUpdateForm from "@/components/LicenceClassUpdateForm.vue";
import { keycloakStore } from "@/store/modules/KeycloakStore";

interface LicenceTypeClass {
  abbreviation: string;
  type: string;
  name: string;
  description: string;
  recommendedValidDays: number;
  id: number;
}

@Component({
  components: {
    LicenceClassForm,
    LicenceClassUpdateForm,
  },
})
export default class LicenceTypes extends Vue {
  api = new LicenceTypeControllerApi();

  licenceTypeClasses: LicenceTypeClass[] = [];

  selectedClass: LicenceTypeClass | undefined;

  search = "";

  showCreate = false;
  showUpdateClass = false;

  get headers() {
    let actions = [];
    if (keycloakStore.isLicenceOfficer)
      actions.push({ text: "Actions", value: "actions", sortable: false });

    const details = [
      { text: "Abbreviation", value: "abbreviation", sortable: true },
      { text: "Type", value: "type", sortable: true },
      { text: "Name", value: "name", sortable: true },
      {
        text: "Recommended Valid Days",
        value: "recommendedValidDays",
        sortable: true,
      },
      { text: "Description", value: "description", sortable: true },
    ];
    const columns = actions.concat(details);
    return columns;
  }

  get licenceOfficer() {
    return keycloakStore.isLicenceOfficer;
  }

  mounted() {
    this.refresh();
  }

  refresh() {
    manageLoading("Loading Licence Types", async () => {
      const response = await this.api.getLicenceTypes();
      licenceStore.setLicenceTypes(response.data);
      const types = response.data;
      this.licenceTypeClasses = [];
      for (let i = 0; i < types.length; i++) {
        const type = types[i];
        if (type.classes === undefined) continue;
        for (let j = 0; j < type.classes.length; j++) {
          this.licenceTypeClasses.push({
            abbreviation: type.abbreviation,
            type: type.name,
            ...type.classes[j],
          });
        }
      }
    });
  }

  deleteClass(id: number) {
    showLoadingDialog(
      DialogType.ERROR,
      "Deleting Licence Class",
      "This action cannot be undone. Are you sure you want to delete this Licence Class?",
      async () => {
        await this.api.removeLicenceClass(id);
        await this.refresh();
      }
    );
  }

  editClass(id: number) {
    const found = this.licenceTypeClasses.find((item) => item.id == id);
    if (found) this.selectedClass = { ...found };
    this.showUpdateClass = true;
  }

  createLicenceClass(payload: any) {
    const { licenceTypeId, ...licenceClass } = payload;
    const id = Number(payload.licenceTypeId);
    manageLoading("Creating Licence Class", async () => {
      await this.api.addLicenceClass(id, licenceClass);
      await this.refresh();
    });
  }

  updateLicenceCLass(payload: any) {
    const id = Number(payload.id);
    const data: LicenceClassPut = { description: payload["description"] };
    manageLoading("Updating Licence Class", async () => {
      await this.api.updateLicenceClass(id, data);
      await this.refresh();
    });
  }
}
